<template >
  <HelpLink URLCZ="https://helpdesk.ekois.cz/a/solutions/articles/43000690398?lang=cs" 
                   URLEN="https://helpdesk.ekois.cz/a/solutions/articles/43000690398?lang=en" 
                PopisLinku='clanky.prevodMajetku'></HelpLink>
  <div height="calc(100% - 57px)">
  
    <div id="prevod">
      <DxPopup id="popup2" v-model:visible="popupAlert" :show-title="true" :drag-enabled="false"
                :hide-on-outside-click="true" :show-close-button="true" :title="$t('central-Varovani')" 
                :contentTemplate="popupAlertTemplate" :width="400" :height="200">
                <template #SmazatPoz> 
                    <h3>{{$t('majetekPrevod-OpravduSiPrejeteSmazat')}}</h3>                   
                  <DxButton class="button1" height="auto" width="auto" type="default" styling-mode="contained" :text="$t('central-Ano')"
                    @click="SetState(-99, true); popupAlert=false " />
                  <DxButton class="button1" height="auto" width="auto" type="default" styling-mode="contained" :text="$t('central-Ne')"
                    @click="popupAlert=false" />
                </template>
        </DxPopup>

        <div id="h2cd">
          <DxButton icon="back" :text="$t('central-VratitSeZpet')" height="auto" type="outlined" styling-mode="contained" id="link"
          @click="$router.push('Pozadavky')" />
        <h2 class="nadpisH2Pozadavky fontAndColor prevent-select " v-if="h">{{$t('majetekPrevod-VytvoreniPrevoduMajetku')}}</h2>
        <h2 class="nadpisH2Pozadavky fontAndColor prevent-select " v-else>{{$t('majetekPrevod-PrevodMajetku')}} {{ poz+' ('+state+')' }} </h2>
        
        </div>

        
        
        <form action="your-action" @submit="onFormSubmit($event)">
          <div id="obaleniFormulare">
          <DxResponsiveBox single-column-screen="sm">
            <DxRow :ratio="1" />
            <DxRow :ratio="1" />
            <DxRow :ratio="1" />
            <DxRow :ratio="1" />
            

            <DxCol :ratio="1" />
            <DxCol :ratio="1" />
            <DxCol :ratio="1" />
            <DxCol :ratio="1" />
            <DxCol :ratio="0.5" />
            <DxItem>
              <DxLocation :row="0" :col="0" :colspan="2" screen="lg" />
              <DxLocation :row="0" :col="0" :colspan="2" screen="md" />
              <DxLocation :row="0" :col="0" :colspan="5" screen="sm" />
              <DxLocation :row="0" :col="0" :colspan="5" screen="xs" />
              <div class="first-bar item">
                <div class="dx-field" v-show="false">
                  <DxSelectBox :disabled="stateid!=4" :id="1" width='auto' height="45px" :label="$t('central-Pres')" :data-source="vias"
                    display-expr="content" value-expr="id" placeholder="" :value="Via[0].id" @value-changed="setSelectedValue">
                    <DxValidator>
                      <DxRequiredRule :message="$t('majetekPrevod-PresMusiBytVyplneno')" />
                    </DxValidator>
                  </DxSelectBox>
                </div>
                
              </div>
            </DxItem>
            <DxItem>
              <DxLocation :row="1" :col="0" :colspan="1" screen="lg" />
              <DxLocation :row="1" :col="0" :colspan="2" screen="md" />
              <DxLocation :row="1" :col="0" :colspan="3" screen="sm" />
              <DxLocation :row="1" :col="0" :colspan="5" screen="xs" />
              <div class="second-bar item">
                <div class="dx-field">
                  <DxSelectBox :disabled="stateid!=4" :id="2" width='90%' height="45px" :label="$t('majetekPrevod-NaZamestnance')"
                    :data-source="zamestnanci" display-expr="content" :placeholder="$t('central-Placeholder')"
                    @value-changed="setSelectedValue" :search-enabled="true" search-mode="contains" :search-timeout="1000"
                    @input="SearchInput" :value="zamestnanec[0]" noDataText="" class="VyrMajetkuBoxes">
                    <DxValidator>
                      <DxRequiredRule :message="$t('majetekPrevod-ZamestnanecMusiBytVyplnen')" />
                    </DxValidator>
                  </DxSelectBox>
                  </div>
                </div>
              
            </DxItem>  
          </DxResponsiveBox>
          </div>
          <div id="obaleniFormulare" class="obaleniFormulare2" padding-top="5px" v-if="!h">          
          <DxResponsiveBox single-column-screen="sm" id="" class="ResponsiveBoxClass" >
            <DxRow :ratio="1" />
            <DxRow :ratio="1" />
            <DxRow :ratio="1" />
            <DxCol :ratio="1" />
            <DxCol :ratio="1" />
            <DxCol :ratio="0.5" />
            <DxCol :ratio="1" />
            <DxCol :ratio="1" />
            <DxCol :ratio="1" />
          <DxItem>
            <DxLocation :row="0" :col="3" :colspan="3" screen="lg" />
            <DxLocation :row="0" :col="3" :colspan="3" screen="md" />
            <DxLocation :row="1" :col="0" :colspan="6" screen="sm" />
            <DxLocation :row="1" :col="0" :colspan="6" screen="xs" />
              <div v-if="!h"> 
                <Attachments :Disabled="stateid != 4" :Pozid="this.poz" :FileUploadFileType="1" :ListFilesfileType="1" :FileDeleteFileType="1"/>
            </div>
            </DxItem>
            <DxItem>
              <DxLocation :row="0" :col="0" :colspan="2" screen="lg" />
                        <DxLocation :row="0" :col="0" :colspan="2" screen="md" />
                        <DxLocation :row="0" :col="0" :colspan="6" screen="sm" />
                        <DxLocation :row="0" :col="0" :colspan="6" screen="xs" />
              <div class="dx-field" v-if="!h">
                
                <DxTextArea class="" :disabled="stateid!=4" id="4" :label="$t('majetekPrevod-Komentar')" width="auto" :height="100" @value-changed="setSelectedValue" v-model:value="komentar"
                  placeholder="" />
              </div>
            </DxItem>
          </DxResponsiveBox>
                   </div>
                   
        
        <div class="table" v-show="!h">
        
     
      <div id="dataGridPrevadenyMajetek">
        <h3 id="h3PrevadenyMajetek">{{$t('majetekPrevod-PrevadenyMajetek')}}</h3>
        <div id="dataGridPrevadenyMajetekGRID">
      <DxDataGrid  :allow-column-resizing="true"  :column-min-width="100" :hoverStateEnabled="true" :rowAlternationEnabled="false" :column-max-width="400" height="550px"
        ref="mygrid" id="DataGrid" :data-source="GridRows" :allow-column-reordering="true" :show-borders="true"
        key-expr="id" @row-click="AddAsset" :noDataText="$t('central-NenalezenyZadneZaznamy')" @option-changed="SearchGrid"
        :editing-texts="{ saveRowChanges: '*SAVE*', cancelRowChanges: '*CANCEL*',}" @initialized="RowsCreated" @row-prepared="RowsCreated" @cellPrepared="onCellPrepared">
        <DxStateStoring
          :enabled="true"
          type="localStorage"
          storage-key="GridPrevodMajetku"
         />
         <DxLoadPanel :enabled="true" :text="$t('central-Loading')"/>
         <DxToolbar >
                                <DxItem 
                                name="groupPanel"  location="before" locate-in-menu="auto"
                                />

                                <DxItem  location="after" locate-in-menu="auto" >
                                    <DxButton icon="revert" id="ObnovitRozlozeni" height="auto" width="auto" :text="$t('central-ObnovitPuvodniRozlozeni')"
                                    type="success" style="scale: 85%;color: inherit; border-color: gray;" styling-mode="outlined" @click="GridLayoutReset" />      
                                </DxItem>
                                
                                <DxItem
                                name="exportButton"  location="after" locate-in-menu="auto"
                                />

                                <DxItem
                                name="searchPanel"  location="after" locate-in-menu="auto"
                                :visible="true"
                                />
            </DxToolbar>
        <DxColumn width="200px" data-field="id" :caption="$t('majetekPrevod-InvCislo')" :allow-editing="false" :visible="true" :formItem="{ visible: false }" class="FontSkupina"/>
        <DxColumn data-field="name" :caption="$t('majetekPrevod-Nazev')" class="FontSkupina">
        </DxColumn>
        <DxColumn width="130px" data-field="date" :caption="$t('majetekPrevod-Zarazeni')" format="dd. MM. yyyy" class="FontSkupina">        
        </DxColumn>
        <DxColumn format=",##0.00" width="130px" data-field="price" :caption="$t('majetekPrevod-PorizovaciCena')" class="FontSkupina">
        </DxColumn>               
         <DxColumn data-field="internalid" :caption="$t('majetekPrevod-InterniId')" :visible="false" :formItem="{ visible: false }" class="FontSkupina">        
        </DxColumn>     
        <DxColumn width="130px" data-field="picked"  data-type="boolean" :caption="$t('majetekPrevod-Vybran')" :visible="true"  :formItem="{ visible: false }" sort-order="desc" :allow-sorting="true" class="FontSkupina">        
        </DxColumn>        
        <DxColumn width="130px" data-field="locked"  data-type="boolean" :caption="$t('majetekPrevod-Blokovan')" :visible="true"  :formItem="{ visible: false }"  class="FontSkupina">        
        </DxColumn>  
        <DxEditing :allow-updating="false" :allow-deleting="false" :allow-adding="false" mode="form" :editRow="$t('majetekPrevod-Upravit')"
          :texts="{
            saveRowChanges: 'Povrdit', cancelRowChanges: 'Zrušit', editRow: '', deleteRow: 'Smazat',
            confirmDeleteTitle: 'Varování', confirmDeleteMessage: 'Opravdu si přejete smazat tento záznam?',
          }" class="FontSkupina" />
        <DxPaging :enabled="false" />
        <DxGroupPanel :visible="true" :empty-panel-text="$t('central-PretahneteSemProSouhrn')" class="FontSkupina" id="PrevadenyMajetekGridPretahneteSem"/>
        <DxGrouping :auto-expand-all="true" />
        <DxSorting :ascending-text="$t('central.Sorting.ascendingText')" :clear-text="$t('central.Sorting.clearText')" :descending-text="$t('central.Sorting.descendingText')" />
        <DxSearchPanel :width="300" :visible="true" :placeholder="$t('central-Hledat')" class="FontSkupina"/>
      </DxDataGrid>
    </div>
    </div>
    </div>
    <DxResponsiveBox single-column-screen="sm"  class=" SpodniMenuDetailPozadavku">
            <DxRow :ratio="1" />
            <DxRow :ratio="1" />
            <DxRow :ratio="1" />
            <DxCol :ratio="1" />
            <DxCol :ratio="1" />
            <DxCol :ratio="1" />
            <DxCol :ratio="1" />
            <DxCol :ratio="1" />

              <DxItem >
                <DxLocation :row="0" :col="0" :colspan="2" screen="lg" />
                <DxLocation :row="0" :col="0" :colspan="2" screen="md" />
                <DxLocation :row="0" :col="0" :colspan="5" screen="sm" />
                <DxLocation :row="0" :col="0" :colspan="5" screen="xs" />
                <div class="child" id="ResponsiveBoxItemActionButtons" v-if="h">
                      <DxButton icon="add" id="vytvorit" height="auto" width="auto" :text="$t('majetekPrevod-ZalozeniPrevoduMajetku')" :use-submit-behavior="true"
                        type="success"  />
                
                      </div>
              </DxItem>

              <DxItem >
                <DxLocation :row="1" :col="0" :colspan="5" screen="lg" />
                <DxLocation :row="1" :col="0" :colspan="5" screen="md" />
                <DxLocation :row="1" :col="0" :colspan="5" screen="sm" />
                <DxLocation :row="1" :col="0" :colspan="5" screen="xs" />
                <DxScrollView direction="horizontal" showScrollbar="always" width="100%" v-if="!h">
                  <div id="ResponsiveBoxItemActionButtons" v-if="!h">
                    <div class="child actionButtonsPozadavkyMargin" v-for="item in actionsPozadavky" :key="item" id="actionbuttons">
                      <DxButton class="button1 actionButtonsPozadavkyResizableA" height="100%" width="auto" type="default" styling-mode="contained"
                        :text="item.nazev" :hint="item.nazev" :id="item.id" @click="SetState(item.id)" :class="item.class" :icon="item.icon"
                        v-show="(item.nazev != 'Schválit') && (item.nazev != 'Vrátit') && (item.nazev != 'Zamítnout')" />
                    </div>
                  </div>
                </DxScrollView>
                  
              </DxItem>
          </DxResponsiveBox>
        </form>
    </div>
  
</div>

</template>

<script>
import axios from 'axios'
import notify from 'devextreme/ui/notify';
import DataSource from 'devextreme/data/data_source';
import { isNull, isUndefined } from 'lodash';
import Attachments from '@/components/Attachments.vue';
import HelpLink from '@/components/HelpLink.vue';
//import { suppliersData } from '@/data.js';
import {
  DxStateStoring,DxLoadPanel,DxToolbar,DxSorting
} from 'devextreme-vue/data-grid';
const suppliersDataSource = new DataSource({
  store: {
    data: '',//suppliersData,
    type: 'array',
    key: 'id',
  },
});
export default {
  name: "App",
  components: {
    Attachments,
    DxStateStoring,
    DxLoadPanel,
    DxToolbar,
    HelpLink,
    DxSorting
  },
  data() {
    return {   
            //  pattern: /^\d{}/i,
            PocetKolonek: 2,
            MountingData: false,
            stateid: 4,
            search: '',
            state: 'Uložený',
            popupAlert: false,
            popupAlertTemplate: '',
            actionsPozadavky: [],
            pozMajetek: [],
            zamestnanci: [],
            zamestnanec: [],
            Via: [{ content: '', id: -1 }],
            komentar:'',
            vias: [],          
            showModal: false,
            popis: '',
            pocet: 1,
            poz: '',
            invC: '',
            h: true,
            AssetNumber: '',
            ProductNumber: '',
            GridRows: [],
               
            }
  },
  computed: {
    dataGrid: function () {
      let self = this;
      return self.$refs.mygrid.instance;
    },
  },
  created(){
    window.MountCount=0
  },
  mounted() {
    this.MountingData = true
   // this.GridOptions()
   // window.localStorage.setItem("refresh-page", 'Prevodmajetku')
    this.ListVias();
    if (window.localStorage.getItem("pozid") != '' && window.history.state.back!=null) {
      this.h=false
      this.poz = window.localStorage.getItem("pozid")
      this.DisplayRequest();
      this.Prilohy()
      
    }
    this.LoadBarvyTheme();
      
   
  },
        
  methods: { 
    LoadBarvyTheme(){
            var elementBarvaTheme = document.getElementsByClassName('dx-theme-accent-as-background-color')[0];
    var computedStyleBarvaTheme = window.getComputedStyle(elementBarvaTheme);
    var FillColorBarvaTheme = computedStyleBarvaTheme.fill;
    var rgbaColorTheme = FillColorBarvaTheme.replace("rgb", "rgba").replace(")", ", 0.25)");
        //console.log('Background Color:', FillColor);
        document.documentElement.style.setProperty('--colorByTheme', FillColorBarvaTheme);
        document.documentElement.style.setProperty('--colorByThemeQuarterOpacity', rgbaColorTheme);
        },  
    onCellPrepared(e) {
      var self = this
      if (e.rowType === "header" && e.column.dataField == 'locked') {
        // window.this.$log.debug(e)
        e.cellElement.addEventListener('mouseover', function () {
          const headerTitle = e.cellElement.querySelector('.dx-datagrid-text-content');
            headerTitle.setAttribute("title", self.$t('majetek-MajetekSoucastiPozadavku'));
          /*  if (headerTitle.scrollWidth > headerTitle.clientWidth) {
              headerTitle.setAttribute("title", "Minimální cena");
            }  */
        });
      }

    },
    SearchDelay(search) {
      let self = this;
      clearTimeout(this.timer)
      // window.this.$log.debug(search)
      this.timer = setTimeout(() => {
        self.search = search
        self.TableData();

      }, 1000)

    },

    SearchGrid: function (e) {
      //  window.this.$log.debug(e)

      if (e.name === "searchPanel") {
        this.SearchDelay(e.value)
      }
      else {
        // this.Detail(params.row.id_vydejky);
      }
    },
    FileDelete(FileID) {
      var self = this;
      var gen = new window.General(window.channel, window.message);
      gen.FileDelete(1, self.poz, FileID,
        function (result) {
          window.this.$log.debug(result);
          self.Prilohy();
        }, window.this.ApiError());

    },
    async downloadItem(url, name, suffix) {
      window.this.$log.debug(suffix)
      suffix = '.pdf'
      axios.get(url, { headers: { 'Accept': 'application/pdf' }, responseType: 'blob' })
        .then(function (response) {
          switch (suffix) {
            case '.pdf': {
              window.this.$log.debug(response)
              const blob = new Blob([response.data], { type: 'application/' + suffix });
              const link = document.createElement('a');
              link.href = window.URL.createObjectURL(blob);
              link.download = name;
              link.click();
              window.this.$log.debug(link)
              window.URL.revokeObjectURL(link.href);
              break;
            }
          }
        })
        .catch(function (error) {
          window.this.$log.debug(error)
          window.this.$log.debug(JSON.stringify(error))
        })
    }, 
    GetHash(FileName, FileID) {
      let self = this;
      var gen = new window.General(window.channel, window.message);
      gen.GetFileHash(FileID,

        function (result) {
          window.this.$log.debug(result);
          self.hash = window.URL1+'/js/download/' + result.Value.value;
          window.this.$log.debug(self.hash + " " + FileName)
          self.downloadItem(self.hash, FileName);

        }, window.this.ApiError());
    },
   
    FileUpload(file) {
      window.this.$log.debug(file)
      var self = this;
      var gen = new window.General(window.channel, window.message);
      var reader = new FileReader();
      reader.onload = (function (theFile) {
        window.this.$log.debug(theFile.name)
        return function (e) {
          gen.FileUpload(1, self.poz, theFile.name,
            e.target.result,
            function (result) {
              window.this.$log.debug(result);
              self.Prilohy()
            }, window.this.ApiError());
        }
      })(file);
      reader.readAsBinaryString(file);

    },
    GridLayoutReset(e) {
      this.$log.debug(e)
      this.$log.debug(this.dataGrid)
      this.dataGrid.state(null);
    },
     SetState(stav, alert) {
      window.this.$log.debug(alert + ' alert<<<??>')
      let self = this;
      var wh = new window.WorkFlow(window.channel, window.message);

      if (stav == -1) {
        window.this.$log.debug('konec')
        this.popupAlert = true
        this.popupAlertTemplate = 'SmazatPoz'
        return 1;
      }
      if (alert == true) stav = -1
      self.open();
      wh.SetState(self.poz, stav, '',
        function (result) {
          window.this.$log.debug(result);
          if (result.ErrorNum.value != 0) {
            notify({
              message: result.ErrorTextCZ.value,
              position: {
                my: 'center top',
                at: 'center top',

              },
            }, 'error', 5000); //'info' - barva templatu, 'success', 'warning', 'error'
          }
          else {
            self.DisplayRequest();
            if (result.ErrorNum.value == 0 && (stav == -1 || stav == 0 || stav == 4)) self.$router.push('/Pozadavky?Vypis=0')

            var stavText;
            switch (stav) {
              case -1: stavText = self.$t('pozadavky-StavZrusen')
                break;
              case 0: stavText = self.$t('pozadavky-StavKeSchvaleni')
                break;
              case 4: stavText = self.$t('pozadavky-StavUlozeny')
                break;
            }
            notify({
              message: self.$t('pozadavky-UvedenDoStavu') + stavText + '".',
              position: {
                my: 'center top',
                at: 'center top',
              },
            }, 'success', 5000);
          }
          self.hide();
        }, window.this.ApiError());

    },
    DisplayRequest() {
      window.this.$log.debug('display request')
      let self = this;
      self.actionsPozadavky.length = 0;
      var wh = new window.WorkFlow(window.channel, window.message);
      wh.GetRequest(self.poz,
        function (result2) {
          window.this.$log.debug(result2);
          self.state = result2.ReqStateText.value;
          self.stateid = result2.ReqState.value;
          for (var x = 0; x < result2.Actions.value.items.length; x++) {
            var id = result2.Actions.value.items[x].Id.value;
                        var colorClass = id === 0 ? "buttonKeSchvaleni" : (id === 4 ? "buttonUlozitProUpravy" : (id === -1 ? "buttonSmazat" : "buttonDefault"));
                        var iconName = id === 0 ? "upload" : (id === 4 ? "floppy" : (id === -1 ? "trash" : ""));
                        self.actionsPozadavky[self.actionsPozadavky.length] = {
                            nazev: result2.Actions.value.items[x].Value.value,
                            id: result2.Actions.value.items[x].Id.value,
                            class: colorClass,
                            icon: iconName,
                            pozid: self.poz
            };
          }
        }, window.this.ApiError()); // window.this.$log.debug('chyba')); //
    },
      RowsCreated(e){
        if(e.data==undefined) return 1; 
        //window.this.$log.debug(e)
        if(e.data.picked==true)
        e.rowElement.bgColor = '#ffa500'
      },
      AddAsset(e) {
        var self = this;
            window.this.$log.debug(e)
            window.this.$log.debug(this.poz+" "+e.data.internalid)
            var wh = new window.WorkFlow(window.channel, window.message);
            if(this.state!='Ke schválení'){

           
            if(e.rowElement.bgColor == ''){
             window.this.$log.debug('add')
              wh.SetAssetTransferItem(this.poz,e.data.internalid,
                function (result) {
                      window.this.$log.debug(result)
                      
                      if(result.ErrorNum.value==0)
                      {
                        e.data.picked = true;
                        e.data.locked=true;
                        e.rowElement.bgColor = '#ffa500';
                        notify({
                          message: self.$t('pozadavky-PrevodMajetkuPridanMajetek'),
                          position: {
                            my: 'center top',
                            at: 'center top',

                          },
                        }, 'success', 3000); //'info' - barva templatu, 'success', 'warning', 'error'
                      } 
                      else {
                          notify({
                            message: result.ErrorTextCZ.value, 
                            position: {
                              my: 'center top',
                              at: 'center top',

                            },
                          }, 'warning', 3000); //'info' - barva templatu, 'success', 'warning', 'error'
                     } 
                      
                }, window.this.ApiError());
            } 
            else {                
              window.this.$log.debug('delete')
              wh.DeleteAssetTransferItem(this.poz,e.data.internalid,
                function (result) {
                      window.this.$log.debug(result)
                  if (result.ErrorNum.value == 0) {
                    e.data.picked = false;
                    e.data.locked = false;
                    e.rowElement.bgColor = ''
                    notify({
                      message: self.$t('pozadavky-PrevodMajetkuOdebranMajetek'),
                      position: {
                        my: 'center top',
                        at: 'center top',

                      },
                    }, 'success', 3000); //'info' - barva templatu, 'success', 'warning', 'error'
                  }
                  else {
                    notify({
                      message: result.ErrorTextCZ.value,
                      position: {
                        my: 'center top',
                        at: 'center top',

                      },
                    }, 'error', 5000); //'info' - barva templatu, 'success', 'warning', 'error'
                  }
                }, window.this.ApiError());
            }
          } else notify({
              message: 'Nelze upravovat položky převodu v tomto stavu požadavku. ('+self.state+')',
              position: {
                my: 'center top',
                at: 'center top',

              },
          }, 'warning', 5000); //'info' - barva templatu, 'success', 'warning', 'error'        
        },
        GetAssetTransfer(id) {
            var self = this;
           self.zamestnanci.length=0
           self.pozMajetek.length=0
           
            var wh = new window.WorkFlow(window.channel, window.message);
            wh.GetAssetTransfer(id,
                function (result) {
                  
                  for (var x = 0; x < result.List.value.items.length;x++) {   
                        self.pozMajetek[self.pozMajetek.length]={
                            name: result.List.value.items[x].Name.value,
                            internalid: result.List.value.items[x].InternalId.value,
                    };
                  }
                    self.Via[0] = { content: result.Request.value.ReqViaText.value, id: result.Request.value.ReqVia.value }
                  self.komentar = result.Request.value.Comment.value
                  if (self.komentar != '') self.PocetKolonek++
                    self.zamestnanec[0] = { content: result.TargetEmployeeTXT.value, id: result.TargetEmployeeID.value }
                    self.zamestnanci[0]= self.zamestnanec[0]
                    window.this.$log.debug(result); 
                        self.TableData()
                        }, window.this.ApiError());
        },
        SetAssetTransfer(param) {
            var self = this;

            var EmpID = this.zamestnanec[0].id
            window.this.$log.debug(EmpID +' '+  self.Via[0].id )
            var wh = new window.WorkFlow(window.channel, window.message);
            if(EmpID>0){ 
            if (param == 'vytvorit') {
         
                    wh.SetAssetTransfer(0, EmpID, self.komentar, self.Via[0].id,
                        function (result) {
                        window.localStorage.setItem("pozid", result.Value.value)
                        self.poz = result.Value.value
                        self.h = false;
                            window.this.$log.debug(result); 
                            self.TableData()
                          self.DisplayRequest();
                        }, window.this.ApiError());
         
            }
            else{ 
              wh.SetAssetTransfer(self.poz, EmpID, self.komentar, self.Via[0].id,
                        function (result) {                     
                          if (result.Error.value.ErrorNum.value == 0) {
                            notify({
                              message: self.$t('pozadavky-PrevodMajetkuZmenyUlozeny'),
                              position: {
                                my: 'center top',
                                at: 'center top',
                              },
                            }, 'success', 3000); 
                            window.this.$log.debug(result);
                          //  self.DisplayRequest();
                          }
                          else {
                            notify({
                              message: result.Error.value.ErrorTextCZ.value,
                              position: {
                                my: 'center top',
                                at: 'center top',
                              },
                            }, 'error', 3000);

                          }
                                }, window.this.ApiError());
          }
         }      
        },
        GetEmployees(employee) {
            if (employee.component != undefined)
            {
                var e = employee
                employee = e.component.option("text")
            }
                var gen=new window.General(window.channel,window.message);  
            let self = this;
            self.zamestnanci.length=0;
              //  window.this.$log.debug(employee)
                gen.ListEmp(employee,
                function (result) {
              //  window.this.$log.debug(result.fResponseObject.result.Result.List.length)
                    window.this.$log.debug(result);
                    for (var x = 0; x < result.List.value.items.length;x++) {   
                        self.zamestnanci[self.zamestnanci.length]={
                            content: result.List.value.items[x].Value.value,
                            id: result.List.value.items[x].Id.value,
                    };
                      if (x > 20) break;
                    }  
                    e.component.getDataSource().reload();   
            },window.this.ApiError());    
            },
        ClearValue(e) {
            if (e.component.option("text")!='') e.component.reset()
        },
    SearchInput(e) {         
            let self = this;
            clearTimeout(this.timer)
            this.timer = setTimeout(() => {
                self.GetEmployees(e)                      
            }, 1000)

        },
    customItemCreating(data) {
      window.this.$log.debug(data)
      window.this.$log.debug(data.text+' text')
      if (!data.text) {
        data.customItem = null;
        return;
      }
      
      
    //  const itemIds = suppliersData.map((item) => item.ID);
    //  const incrementedId = Math.max.apply(null, itemIds) + 1;
      const newItem = {
        content: data.text,
       // id: incrementedId,
      };
      data.customItem = suppliersDataSource
        .store()
        .insert(newItem)
        .then(() => suppliersDataSource.load())
        .then(() => newItem)
        .catch((error) => {
          throw error;
        });
    },

    GridOptions(){
      window.this.$log.debug(this.dataGrid)
    },
    onFormSubmit(e) {
    
      window.this.$log.debug(e.submitter.attributes[0].ownerDocument.activeElement.id)
      window.this.$log.debug(e)
     
      
      if (e.submitter.attributes[0].ownerDocument.activeElement.id == "vytvorit") {
        notify({
          message: this.$t('pozadavky-PrevodMajetkuZalozen'),
          position: {
            my: 'center top',
            at: 'center top',
          },
        }, 'success', 3000);
          this.SetAssetTransfer("vytvorit", true)
          
      }
      if (e.submitter.attributes[0].ownerDocument.activeElement.id == "upravit") {
        notify({
          message: this.$t('pozadavky-PrevodMajetkuUpraven'),
          position: {
            my: 'center top',
            at: 'center top',
          },
        }, 'success', 3000);
      this.SetAssetTransfer("upravit")
      }
       if (e.submitter.attributes[0].ownerDocument.activeElement.id == "prevest") {
        notify({
          message: this.$t('pozadavky-MajetekPreveden'),
          position: {
            my: 'center top',
            at: 'center top',
          },
        }, 'success', 3000);
         // this.SetAssetTransfer("vytvorit", true)
          
      }
      


      e.preventDefault();
    },
    setEditedValue(valueChangedEventArg, cellInfo) {
      window.this.$log.debug(cellInfo +' DATAAA')
      window.this.$log.debug(valueChangedEventArg)
    //  this.mena = valueChangedEventArg.value
      cellInfo.setValue(valueChangedEventArg.value.content);
    },
    RefreshDataGrid() {
      this.dataGrid.refresh()
        .then(function () {
          // ...
        })
        .catch(function (error) {
          window.this.$log.debug(error)
          // ...
        });
    },

    startEdit(e) {
     // window.this.$log.debug(e)
      e.component.editRow(e.rowIndex);  
      },
    setSelectedValue(e) {
      window.MountCount++
      window.this.$log.debug(e);
      window.this.$log.debug(window.MountCount + 'MountCount');
      window.this.$log.debug(this.PocetKolonek + 'Dokolika');
      if (window.MountCount == this.PocetKolonek + 1) this.MountingData = false // tohle neni uplne dobry napad delat
      switch (e.element.id) {
        case '1':
          this.Via[0].id = e.value     
          break;
        case '2':
          this.zamestnanec[0] = e.value
          break;
        case '3':
          break;
        case '4':
          //this.komentar = e.value
          break;
      }
        if (!isUndefined(e.value) && !isNull(e.value) && !this.h && !this.MountingData)
      this.SetAssetTransfer("upravit")
      // this.SkipDiacritics(this.typpozadavek2.content)
      //  this.pozadavek=e.value
      // this.selectedValue = e.value;
    },
    
    open() {
      window.loader = this.$loading.show({
        loader: 'dots'
      });
    },
    hide() {
      window.loader.hide()
    },

    TableData() {
      var asset = new window.Asset(window.channel, window.message);
        let self = this;
      var EmpID= JSON.parse(window.localStorage.getItem("user")).EmployeeID.value 
      self.GridRows.length = 0;
   //   this.open();
      asset.GetAsset(EmpID,true,this.search,0,1,
        function (result) {
          window.this.$log.debug(result);
          var x;
          var i;
        if(self.pozMajetek.length!=0){
          for ( x = 0; x < result.List.value.items.length; x++) {

             i = self.GridRows.length;
              self.GridRows[i] = {  
                id: result.List.value.items[x].Id.value,
                name: result.List.value.items[x].Name.value,
                price: result.List.value.items[x].Price.value, 
                date: result.List.value.items[x].From.value,
                internalid: result.List.value.items[x].InternalId.value,    
                locked: result.List.value.items[x].Locked.value,              
                picked: false,
            };
            
           }
          
          for (var y = 0; y < self.pozMajetek.length;y++) {   
           for ( x = 0; x < result.List.value.items.length; x++) {

             // i = self.GridRows.length;
             if(result.List.value.items[x].InternalId.value==self.pozMajetek[y].internalid){
                self.GridRows[x].picked = true
             }
                
                               
               }
               
          }
      
        }
        else {
          for ( x = 0; x < result.List.value.items.length; x++) {

             i = self.GridRows.length;
              self.GridRows[i] = {  
                id: result.List.value.items[x].Id.value,
                name: result.List.value.items[x].Name.value,
                price: result.List.value.items[x].Price.value, 
                date: result.List.value.items[x].From.value,
                internalid: result.List.value.items[x].InternalId.value, 
                locked: result.List.value.items[x].Locked.value,                
                picked: false,
            };
            
           }
          }
          self.RefreshDataGrid()
      //    self.hide();
        }, window.this.ApiError());

    },
        ListVias()
        {
            let self = this;
            self.vias.length = 0;
            var gen=new window.General(window.channel,window.message);
              gen.ListLookup('REQUEST_VIAS[2]','',1,50,
              function (result) {
               // window.this.$log.debug(result);
                for (var x = 0; x < result.List.value.items.length; x++) {

                  //  window.this.$log.debug(result.List.value.items[x]);
                  var i = self.vias.length;
                  self.vias[i] = {
                    content: result.List.value.items[x].Value.value,
                    id: result.List.value.items[x].Id.value,
                  };
                }
                if(self.h)
                  self.Via[0] = self.vias[0]
                else
                  self.GetAssetTransfer(self.poz)
              }, window.this.ApiError());
              
    },
        FreeAddr(){
          window.this.$log.debug('smazano')
          suppliersDataSource.length=0;
          },             
          },
    }
      
</script>

<style scoped>


#upravit {
  margin-bottom: 10px;
}
.actionsbuttons {
    clear: both; 
    flex-direction: row;

}
#prevest {
    background-color: #ffa500;
    border-color: #ffa500;
    margin-left: 100px;
}
.dx-row.dx-data-row.dx-column-lines {
  background-color: #ffa500;
}
/*

.table{
  width: auto;
}
*/
#button{
  margin-right: 40px;
}
#button1{
  margin:5px
}
 label{
  margin-right:20px;
 }
 #text1{
  text-align: top;
  height: 70px;
  width: 300px;
 }
 input{
  text-align: top;
  margin-right: 40px;
 }
 #h2c,#h2d{
  text-align: center;
  margin: 0px 0px 0px;
  margin-bottom: 0px;
  
}
#link{
  float: left;
}
#obaleniFormulare{border-style: solid;
  border-color: rgba(128, 128, 128, 0.1);
    border-radius: 10px;
    background-color: rgba(128, 128, 128, 0.075);
    padding-top: 10px;
    margin-top: 5px;
    }
    .VyrMajetkuBoxes{
  margin: 10px;
  
}

#dx-field-NaZamestnance{
  margin-left: 50px;
}
#dataGridPrevadenyMajetek{
  border-style: ridge;
    border-color: rgba(128, 128, 128, 0.1);
    border-radius: 10px;
    padding: 0px 0px 0px;
    border-radius: 8px;
    border-width: 1.5px;
    width: 100%;
    margin-bottom: 80px;
}
#h3PrevadenyMajetek{
  font-weight: 500;
}
#dataGridPrevadenyMajetekGRID{padding:15px}
#h3PrevadenyMajetek{
  margin: 0px;
  
  padding: 5px;
  
   
}

#PrevadenyMajetekGridPretahneteSem{
  margin-left: 15px;
}

.ResponsiveBoxClass{
      padding: 10px;
    }
    .obaleniFormulare2{margin-top: 10px !important; margin-bottom: 10px !important;}
    .childOfButtons{display: flex !important; flex-direction: row !important;}
    .dx-template-wrapper .dx-item-content .dx-box-item-content{display: flex !important; flex-direction: row !important;}
@media only screen and (max-width: 768px) {
  
  #dx-field-NaZamestnance{margin-left: 0px;}
    
  }/*xs */
  @media only screen and (min-width: 778px) and (max-width: 992px) {
    
    #dx-field-NaZamestnance{margin-left: 0px;}
    
}/*sm */
  @media only screen and (min-width: 992px) and (max-width: 1200px) {
    

   
    }/*md */
  @media only screen and (min-width: 1200px) {

  } /*lg */
  #ResponsiveBoxItemActionButtons{flex-direction: row !important; justify-content: flex-start !important;} 
  .buttonDefault:hover,.buttonUlozitProUpravy:hover,.buttonKeSchvaleni:hover,.buttonSmazat:hover,.buttonKEditaci:hover{
    background-color: var(--colorByThemeLighter);
    border: 0px;
    
}
.buttonKeSchvaleni{
  background: #5cb83c;
  border-color: none;
  border: 0px;
}
.buttonUlozitProUpravy{
    background: #3cb8b8;
    border-color: none; 
    border: 0px;
}
.buttonSmazat{
    background: #d9534f;
  border-color: none;
  border: 0px;
}
.buttonKEditaci{
    background: #ffcc33;
    border-color: none;
    border: 0px; 
}
.buttonDefault{
    background: none;
    border-color: none;
    border: 0px;
}

</style>