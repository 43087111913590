<template>
    <div class="manualBookDiv">
        <a class="manualBookIconDiv" :href="returnURL(URL)" target="_blank">
            <img class="manualBookIcon" src="@/assets/images/manual_book.png">
            <text id="napovedaText">{{ translatedStringA + " " + translatedStringB }}</text>
        </a>
    </div>
</template>

<script>
//import notify from 'devextreme/ui/notify';

export default {
    name: 'HelpLink',
    components: {

    },
    props: {
        Disabled: {
            type: Boolean,
            required: false
        },
        PopisLinku: {
            type: String,
            required: false,
            default: 'clanky.Manual'
        },
        URLEN: {
            type: String,
            required: false,
            default: 'https://helpdesk.ekois.cz/support/solutions/folders/43000697394'
        },
        URLCZ: {
            type: String,
            required: false,
            default: 'https://helpdesk.ekois.cz/support/solutions/folders/43000588472'
        },
        WidthIcon: {
            type: String,
            required: false,
            default: '40px'
        },
        HeightIcon: {
            type: String,
            required: false,
            default: '40px'
        }

    },
    watch: {
    },
    computed: {
        translatedStringA() {
            return this.$t('clanky.Manual');
        },
        translatedStringB() {
            return this.$t(this.PopisLinku);
        },
        URL() {
            if (this.$i18n.locale == 'en') {
                return this.URLEN;
            }
            else { return this.URLCZ; }
        }

    },
    mounted() {
        this.MountedSetup()
        if (window.localStorage.getItem("jazyk") != undefined) { this.$i18n.locale = window.localStorage.getItem("jazyk") }
    },

    methods: {
        MountedSetup() {
            if (this.WidthIcon != this.WidthIcon.default) {
                document.documentElement.style.setProperty('--widthOfIconByInput', this.WidthIcon);
            }
            if (this.HeightIcon != this.HeightIcon.default) {
                document.documentElement.style.setProperty('--heightOfIconByInput', this.HeightIcon);
            }
        },
        returnURL(inputURL) {

            if (this.isValidURL(inputURL)) {
                //window.open(inputURL, '_blank');
                return inputURL;
            } else {
                console.error('Invalid URL:', inputURL);
                return 'https://helpdesk.ekois.cz/support/solutions/folders/43000588472';
            }
        },
        isValidURL(url) {
            var urlPattern = /^(ftp|http|https):\/\/[^ "]+$/;
            return urlPattern.test(url);
        }
    },

};
</script>
<style scoped>
element {
    --heightOfIconByInput: 16px;
    --widthOfIconByInput: 16px;
}

.manualBookIcon {
    position: absolute;
    top: 0;
    padding: 1.5px;
    height: var(--heightOfIconByInput);
    width: var(--widthOfIconByInput);
    align-self: center;
    cursor: pointer;
    margin-left: -0.75px;

}

.manualBookIconDiv {
    position: absolute;
    top: 0;
    right: 2.5%;
    height: var(--heightOfIconByInput);
    width: var(--widthOfIconByInput);
    cursor: pointer;
    background-color: rgba(255, 255, 255, 0.15);
    z-index: 1;
}

/*.manualBookIconDiv:hover {
    position: absolute;
    top: 0;
    right: 5%;
    /*border: 1px solid black;
    border-radius: 27.5%;
    border-top: 0;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    height: calc(var(--heightOfIconByInput)*1.5);
    width: var(--widthOfIconByInput);
    background-color: white;
}

.manualBookIconDiv:hover .manualBookIcon {
    top: calc(var(--heightOfIconByInput)/2.5);
}*/

@media screen and (max-width:700px) {
    .manualBookIcon {
        position: absolute;
        top: 0;
        padding: 1px;
        height: calc(var(--heightOfIconByInput)/1.5);
        width: calc(var(--widthOfIconByInput)/1.5);
        align-self: center;
        cursor: pointer;
        margin-left: -0.75px;
    }

    .manualBookIconDiv {
        position: absolute;
        top: 0;
        right: 2.5%;
        height: calc(var(--heightOfIconByInput)/1.5);
        width: calc(var(--widthOfIconByInput)/1.5);
    }

    /*
    .manualBookIconDiv:hover {
        position: absolute;
        top: 0;
        left: 50%;
        right: 50%;
        border: 1px solid black;
        border-radius: 27.5%;
        border-top: 0;
        border-top-left-radius: 0;
        border-top-right-radius: 0;
        height: calc(calc(var(--heightOfIconByInput)*1.15/1.25));
        width: calc(var(--widthOfIconByInput)/1.5);
    }

    .manualBookIconDiv:hover .manualBookIcon {
        top: calc(var(--heightOfIconByInput)/4);
    }*/
    #napovedaText {
        font-size: 60% !important;
        right: calc(var(--widthOfIconByInput)) !important;
    }
}

.manualBookIconDiv .manualBookDiv {
    height: 0;
    overflow: visible;
}

#napovedaText {
    visibility: hidden;
    width: auto;
    height: auto;
    position: fixed;
    background-color: rgba(51, 51, 51, 0.75);
    color: #fff;
    border-radius: 4px;
    padding: 2.5px;
    white-space: break-spaces;
    z-index: 0;
    font-size: 80%;
    right: 7.5%;
    /*calc(var(--widthOfIconByInput)*1.5);*/
    overflow: hidden;



}

.manualBookIconDiv:hover #napovedaText {
    visibility: visible;
    cursor: text;
}

/*.manualBookIconDiv:hover #napovedaText[title]:after {
    content: attr(title);
    visibility: visible;
    color: #fff;
    background: #333;
    background: rgba(51, 51, 51, 0.75);
    padding: 2.5px;
    opacity: 0;
}*/
</style>