
export const navigationCZ = [
    {
    id: '9',
    text: 'Aktuálně',
    icon:"home" ,

    },   
    {  
    id: '1',
    color: 'red',
    text: 'Požadavky',
    icon:"activefolder" ,
    expanded: true,

  },
    {
    id: '8',
    text: 'Organizace',
    icon:"globe" ,
    expanded: true,

    },
    {
    id: '2',
    text: 'Grantové výkazy',
    icon:"group" ,
    expanded: true,

  },

    {
    id: '3',
    text: 'Telefonní seznam',
    icon:"card" ,
    expanded: false,
    },
    {
    id: '4',
    text: 'Majetek',
    icon:"product",
    expanded: false,
    items: []
    },    
  {
    visible: false,
    id: '5',
    text: 'Dokumenty',
    icon:"doc",
    expanded: false,
    items: []
  },
    {
    id: '6',
    text: 'Dotazovač',
    icon:"search",
    expanded: false,
    items: []

    },
    {
    id: '7',
    text: 'Sklad',
    icon:"toolbox",
    //expanded: true,
    //items: [{text: 'Výdejky', id: '7_3'}]

    },
    {
    id: '10',
    text: 'Výdejky',
    icon:"print",
    //expanded: true,


    },

];
export const navigationEN = [
  {
  id: '9',
  text: 'News',
  icon:"home" ,

  },   
  {  
  id: '1',
  color: 'red',
  text: 'Requests',
  icon:"activefolder" ,
 

},
  {
  id: '8',
  text: 'Organisation',
  icon:"globe" ,

  },
  {
  id: '2',
  text: 'Grants',
  icon:"group" ,
  expanded: true,

},

  {
  id: '3',
  text: 'Phone list',
  icon:"card" ,
  expanded: false,
//
  },
  {
  id: '4',
  text: 'Assets',
  icon:"product",
  expanded: false,
  items: []
  },    
  {
  id: '6',
  text: 'Reports',
  icon:"search",
  expanded: false,
  items: []

  },
   {
  id: '7',
  text: 'Warehouse',
  icon:"toolbox",
  expanded: true,

  },
   {
    id: '10',
    text: 'Issue slips',
    icon:"print",
    //expanded: true,


    },

];

var jazyk
        if (window.localStorage.getItem("jazyk") != undefined) {
          jazyk = window.localStorage.getItem("jazyk")
        }
        if (jazyk == 'en') jazyk = navigationEN
        else jazyk = navigationCZ

export const navigation=jazyk
export default {
  navigation,
  navigationEN,
  navigationCZ,
  getNavigation() { 
  return navigationCZ, navigationEN;
  },
};



