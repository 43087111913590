<template>
<!-- <DxButton v-if="ButtonVisible" id="buttoninstruction" icon="minus" type="default" styling-mode="contained"
        width="40px" @click="TogglePopup" />
-->    
    <DxPopup ref="popupinstructions" :title="$t('granty-Pokyny')" width="300px" height="auto" :showCloseButton="false"
        v-model:visible="PopupVisible" :drag-enabled="true" :focusStateEnabled="true" :shading="false">
        <DxToolbarItem 
            widget="dxButton" 
            :options="buttonOptions" 
            location="after">
        </DxToolbarItem>
        <DxPosition at="left top" my="left top" collision="fit" of="#napovedaText" :offset="{ y: 0, x: -120 }" />
        <!--<DxPosition at="left center" my="right center" collision="fit" of="#zmenavzhled"  :offset="{ y: -22, x: -5 }"/> -->
        <div>
            <div v-for="item in Instrukce" :key="item">
                <div id="htmlObsahDetail" v-html="item.html"></div>
            </div>
        </div>
    </DxPopup>

</template>

<script>
//import notify from 'devextreme/ui/notify';
import DxPopup, { DxToolbarItem } from 'devextreme-vue/popup';
export default {
    name: 'HelpLink',
    components: {
        DxPopup,
        DxToolbarItem
    },
    props: {
        FundingID: {
            type: Number,
            required: true
        },
        Ident: {
            type: String,
            required: false,
            default: ''
        },
    },
    watch: {
        FundingID(newFundingID) {
            this.$log.debug(newFundingID)
            this.GetFundingInstruction();
        },
    },
    data() {
        return {
            buttonOptions: {
                text: '',
                icon: 'minus',
                onClick: this.TogglePopup
            },
            ButtonVisible: false,
            PopupVisible: false,
            EditPopis: '',
            converted: '',
            Instrukce: [],
            zamestnanec: null,
            prevEmpId: null,
        };
    },
    computed: {


    },
    mounted() {
        //  this.GetFundingInstruction();
    },

    methods: {

        TogglePopup(e){
            var popupInstance=this.$refs.popupinstructions.instance
            var currentHeight = popupInstance.option('height');
            this.$log.debug(currentHeight)
            if (currentHeight > 50 || currentHeight== 'auto' ) {
                popupInstance.option('height', 50);
                e.component.option('icon', 'plus');
            } else {
                popupInstance.option('height', 'auto');
                e.component.option('icon', 'minus');
            }
        },      
        SimpleRtfToHtml(rtf) {
            this.$log.debug('volamsimplertfconvertor');

    let html = rtf
        .replace(/^\{\\rtf1[^]*?\\viewkind4\\uc1\s*/, '') // Remove RTF header
        .replace(/\\pard[^\S\r\n]*\\widctlpar[^\S\r\n]*\\sl\d+[^\S\r\n]*\\slmult\d+[^\S\r\n]*/g, '<p>') // Start new paragraph
        .replace(/\\par[d]?/g, '<br/>') // Replace new lines
        .replace(/\\b\b/g, '<b>') // Bold start
        .replace(/\\b0\b/g, '</b>') // Bold end
        .replace(/\\i\b/g, '<i>') // Italic start
        .replace(/\\i0\b/g, '</i>') // Italic end
        .replace(/\\ul\b/g, '<u>') // Underline start
        .replace(/\\ulnone\b/g, '</u>') // Underline end
        .replace(/\\lang\d+\b/g, '') // Remove language tags
        .replace(/\\f\d/g, '') // Remove font change tags
        .replace(/\\'B7\\tab/g, '⦁\t') // Correctly replace bullet points with HTML bullet and tab
        .replace(/\\tab/g, ' ') // Convert tabs to spaces for better formatting
        .replace(/\\fi-?\d+/g, '') // Remove first line indent
        .replace(/\\li\d+/g, '') // Remove line indent
        .replace(/\\sa\d+/g, '') // Remove space after
        .replace(/\\sb\d+/g, '') // Remove space before
        .replace(/\\\*\W*/g, '') // Remove asterisk-prefixed groups
        .replace(/\\[a-z]+\d*/g, '') // Remove all other control words
        .replace(/pn\d*/g, ''); // Explicitly remove 'pn' artifacts

    html = html.replace(/\\'([0-9a-fA-F]{2})/g, function(match, p1) {
        let charCode = parseInt(p1, 16);
        const latin1ToUtf = {
            0xe1: 'á', 0xc1: 'Á', 0xe9: 'é', 0xc9: 'É', 0xed: 'í', 0xcd: 'Í',
            0xf3: 'ó', 0xd3: 'Ó', 0xfa: 'ú', 0xda: 'Ú', 0xfd: 'ý', 0xdd: 'Ý',
            0xe4: 'ä', 0xc4: 'Ä', 0xe8: 'č', 0xc8: 'Č', 0xf8: 'ř', 0xd8: 'Ř',
            0x9a: 'š', 0x8a: 'Š', 0xec: 'ě', 0xcc: 'Ě',
            0xf9: 'ů', 0xd9: 'Ů', 0xf2: 'ň', 0xd2: 'Ň',
            0x8e: 'Ž', 0x9e: 'ž'
        };
        return latin1ToUtf[charCode] || String.fromCharCode(charCode);
    });

    // Remove all remaining RTF control symbols and groups
    html = html.replace(/\\[\w\\]+/g, '').replace(/[{}]/g, ''); // Clean up any leftover curly braces and unrecognized control words

    // Remove any remaining 'pn·' and trailing spaces
    html = html.replace(/pn·\s*/g, '').replace(/·\s*/g, '');

    // Trim and clean up the resulting HTML
    html = html.trim().replace(/<br\/>+/g, '<br/>'); // Remove excessive breaks

    // Log the resulting HTML for debugging
    this.$log.debug(html);

    // Return the converted HTML
    return html;
},
        GetFundingInstruction() {
            var self = this
            self.Instrukce.length = 0
            var grants = new window.Grants(window.channel, window.message);
            grants.GetFundingInstruction(self.FundingID, self.Ident, function (result) {
                window.this.$log.debug('fundinginstruction');
                window.this.$log.debug(result);
                if (result.List.value.items.length > 0) {
                    for (var x = 0; x < result.List.value.items.length; x++) {
                        self.Instrukce[x] = {
                            id: x,
                            value: result.List.value.items[x].Value.value,
                            detail: result.List.value.items[x].Detail.value,
                            html: self.SimpleRtfToHtml(result.List.value.items[x].Value.value)
                        }
                    }
                    self.PopupVisible = true
                    self.ButtonVisible = true
                }
                else {
                    self.PopupVisible = false
                    self.ButtonVisible = false
                }
            }, window.this.ApiError());

        },

    },

};
</script>
<style scoped>
#buttoninstruction {
    margin-bottom: 5px;
    margin-left: 5px;
}
</style>