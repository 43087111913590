<template>
<HelpLink URLCZ="https://helpdesk.ekois.cz/a/solutions/articles/43000690322?lang=cs" 
  URLEN="https://helpdesk.ekois.cz/a/solutions/articles/43000690322?lang=en" 
PopisLinku='clanky.objednavkaZasobovani'></HelpLink>
  <div height="calc(100% - 57px)">
    <div id="objednavky">
      <DxPopup id="popup2" v-model:visible="popupAlert" :show-title="true" :drag-enabled="false"
        :hide-on-outside-click="true" :show-close-button="true" :title="$t('central-Varovani')" :contentTemplate="popupAlertTemplate"
        :width="400" :height="200">
        <template #SmazatPoz>
          <h3>{{$t('central-OpravduSiPrejeteSmazat')}}</h3>
          <DxButton class="button1" height="auto" width="auto" type="default" styling-mode="contained" :text="$t('central-Ano')"
            @click="SetState(-99, true); popupAlert=false " />
          <DxButton class="button1" height="auto" width="auto" type="default" styling-mode="contained" :text="$t('central-Ne')"
            @click="popupAlert=false" />
        </template>
      </DxPopup>

      <div id="HorniMenuObjZasobovani">
        <form action="" @submit="onFormSubmit($event)">
          <DxButton icon="back" :text="$t('pozadavky-ZpetNaPozadavky')" height="auto" type="outlined" styling-mode="contained" id="link"
            @click="$router.push('Pozadavky')" />
          <h2 class="nadpisH2Pozadavky fontAndColor prevent-select" v-if="h">{{$t('central-ZalozeniObjPresZasobovani')}}</h2>
          <h2 class="nadpisH2Pozadavky fontAndColor prevent-select" v-else>{{$t('central-ObjPresZasobovani')}} {{ obj +' ('+state+')'}}</h2>
        </form>
      </div>
      
      <div id="obaleniGrid">
        <form ref="myForm" action="" @submit="onFormSubmit($event)">
            <DxResponsiveBox single-column-screen="sm" id="PrvniResponsiveBox" class="ResponsiveBoxClass">
              <DxRow :ratio="1" />
              <DxRow :ratio="1" />
              <DxRow :ratio="1" />
              <DxCol :ratio="1" />
              <DxCol :ratio="1" />
              <DxCol :ratio="0.10" />
              <DxCol :ratio="1" />
              <DxCol :ratio="1" />
              

                <DxItem>
                  <DxLocation :row="2" :col="0" :colspan="1" screen="lg" />
                  <DxLocation :row="2" :col="0" :colspan="1" screen="md" />
                  <DxLocation :row="2" :col="0" :colspan="1" screen="sm" />
                  <DxLocation :row="2" :col="0" :colspan="1" screen="xs" />
                  <div class="dx-field" v-if="false">
                    <div class="dx-field" v-show="false">
                      <DxSelectBox :disabled="stateid!=4" :id="1" width='250px' height="45px" :label="$t('central-Pres')"
                        :data-source="vias" display-expr="content" value-expr="id" placeholder="" :value="objVia[0].id"
                        @value-changed="setSelectedValue" class="DxSelectBoxResponsive">
                        <DxValidator>
                          <DxRequiredRule :message="$t('central-PresMusiBytVyplneno')" />
                        </DxValidator>
                      </DxSelectBox>
                    </div>
                    </div>
                  
                </DxItem>

                <DxItem>
                  <DxLocation :row="0" :col="0" :colspan="2" screen="lg" />
                  <DxLocation :row="0" :col="0" :colspan="2" screen="md" />
                  <DxLocation :row="0" :col="0" :colspan="5" screen="sm" />
                  <DxLocation :row="0" :col="0" :colspan="5" screen="xs" />
                  <div class="flexboxcontainer">
                    <FundingInstructions :FundingID="currentGrant" Ident="Pozadavky1"> </FundingInstructions>
                        <DxSelectBox style="flex-grow: 1;" :disabled="stateid != 4" :id="12" width="auto" height="45px" :label="$t('central-Grant')"
                          :data-source="granty" display-expr="content" value-expr="id" placeholder="" 
                        :value=" currentGrant " @value-changed="setSelectedValue($event); " class="DxSelectBoxResponsive">
                          <DxValidator >
                            <DxRequiredRule :message="$t('central-GrantMusiBytVyplnen')" />
                          </DxValidator>
                        </DxSelectBox>
                    </div>
                      <div id="container" v-if="!h">
                        <div id="inner">
                          <div class="DxSelectBoxResponsive ">
                            <DxCheckBox :disabled="stateid != 4" :id="9" v-model:value="checked" height="45px" @value-changed="setSelectedValue" />{{
                            $t('central-UrgentniDodani')}}
                          </div>
                          <div class="child DxSelectBoxResponsive" v-if="checked">
                            <DxNumberBox :disabled="stateid != 4"  @value-changed="setSelectedValue" :id="8" :show-spin-buttons="true" DxTextBoxclass="DxSelectBoxResponsive" :min="0" :max="90" labelMode="floating" type="number" height="45px" :label="$t('central-TerminDodani')+' (max 90)'" v-model:value="dnyDoDodani" :use-submit-behavior="true"
                              placeholder=''
                              :format="{
                                type: 'fixedPoint',
                                precision: 2,
                                thousandSeparator: ' ',
                                decimalSeparator: ','
                              }">
                              <DxValidator>
                                <DxNumericRule :message="$t('central-PocetMusiBytCislo')" />
                                <DxRequiredRule :message="$t('central-PocetMusiBytVyplnen')" />
                              </DxValidator>
                            </DxNumberBox>
                          </div>
                          <div class="child DxSelectBoxResponsive " v-if="checked">
                            <DxSelectBox  :disabled="stateid!=4" :id="7" width='400px' height="45px" :label="$t('central-UrgentniDodaniTypy')" :data-source="typyDodani"
                              display-expr="content" value-expr="id" placeholder="" :value="typDodani[0].id" @value-changed="setSelectedValue"
                              class="">
                              <DxValidator>
                                <DxRequiredRule :message="$t('central-PresMusiBytVyplneno')" />
                              </DxValidator>
                            </DxSelectBox>
                          </div>
                        </div>
                      </div>
                </DxItem>

                <DxItem>
                  <DxLocation :row="0" :col="3" :colspan="2" screen="lg" />
                  <DxLocation :row="0" :col="3" :colspan="2" screen="md" />
                  <DxLocation :row="1" :col="0" :colspan="5" screen="sm" />
                  <DxLocation :row="1" :col="0" :colspan="5" screen="xs" />
                  <div class="third-bar item">
                    <div class="dx-field">
                          <Suppliers :Disabled=" stateid != 4 || (katalogVybran && katalog) " :Validation=" SuppliersValidate " :id="3" :Supplier=" objSupplier[0] " @value-changed=" setSelectedValue " class="DxSelectBoxResponsive" />
                          <DxButton v-if=" h " class="DxSelectBoxResponsive" id="AnySupplierButton" height="auto" width="auto" type="default" styling-mode="outlined" :text=" $t('pozadavky-ObjednavkaLibovolnyDodavatel') "
                          @click=" setSelectedValue" />  
                      </div> 
                  </div>
                </DxItem>

            </DxResponsiveBox>

            <DxResponsiveBox single-column-screen="sm" id="DruhyResponsiveBox" class="ResponsiveBoxClass" v-if="!h" width="100%">
                    <DxRow :ratio="1" />
                    <DxRow :ratio="1" />
                    <DxRow :ratio="1" />
                    <DxCol :ratio="1" />
                    <DxCol :ratio="1" />
                    <DxCol :ratio="0.5" />
                    <DxCol :ratio="1" />
                    <DxCol :ratio="1" />
                    <DxCol :ratio="1" />
                <DxItem>
                        <DxLocation :row="0" :col="0" :colspan="2" screen="lg" />
                        <DxLocation :row="0" :col="0" :colspan="2" screen="md" />
                        <DxLocation :row="0" :col="0" :colspan="6" screen="sm" />
                        <DxLocation :row="0" :col="0" :colspan="6" screen="xs" />
                      <div class="dx-field"  v-if="!h">
                        <DxTextArea :disabled="stateid!=4" :id="4" :label="$t('central-Komentar')" width="auto" :height="100"
                          v-model:value="komentar" placeholder="" :use-submit-behavior="true"  @value-changed="setSelectedValue($event); " class="DxSelectBoxResponsive" :maxLength="200" />
                      </div>
                  
                </DxItem>

                <DxItem>  
                        <DxLocation :row="0" :col="3" :colspan="3" screen="lg" />
                        <DxLocation :row="0" :col="3" :colspan="3" screen="md" />
                        <DxLocation :row="1" :col="0" :colspan="6" screen="sm" />
                        <DxLocation :row="1" :col="0" :colspan="6" screen="xs" />
                        <Attachments v-if="!h" :Disabled="stateid != 4" :Pozid="this.obj" :FileUploadFileType="1" :ListFilesfileType="1" :FileDeleteFileType="1"/>

                </DxItem>
            </DxResponsiveBox>
            <div class="table" v-show="!h" id="obaleniDataGridu" max-width="100%">
        <h3>{{ $t("central-PolozkyObjednavky:")}}</h3>
        <DxDataGrid width="100%" :allow-column-resizing="true" :column-min-width="60" :ref="dataGridRefKey" id="DataGridObjednavky" :hoverStateEnabled="true" :rowAlternationEnabled="true"
          :data-source="GridRows" :allow-column-reordering="true" :show-borders="true" key-expr="Id" column-resizing-mode="widget" 
          @row-click="startEdit" :noDataText="$t('central-NenalezenyZadneZaznamy')" @init-new-row="InitNewRow" @saved="GridEditting"
          @editing-start="InitNewRow" 
          :editing-texts="{ saveRowChanges: '*SAVE*', cancelRowChanges: '*CANCEL*',}"
          mode='batch'
          >
          <DxEditing 
            :allow-updating="stateid == 4" :allow-deleting="stateid == 4"
            :allow-adding="stateid == 4" mode="form" :editRow="$t('central-Upravit')" :texts="{ addRow: $t('central-Pridat'),
              saveRowChanges: $t('central-Potvrdit'), cancelRowChanges: $t('central-Zrusit'), editRow: '', deleteRow: $t('central-Smazat'),
              confirmDeleteTitle: $t('central-Varovani'), confirmDeleteMessage: $t('central-OpravduSiPrejeteSmazatZaznam'),
            }">
            <DxForm>
              <DxItem :col-count="1" :col-span="1" item-type="group">
                <DxItem data-field="description" />
              </DxItem>
              <DxItem :col-count="2" :col-span="1" item-type="group">
                <DxItem data-field="ProductNumber" />
                <DxItem data-field="specid" :editor-options="{ width: 195 }" />          
              </DxItem>
              <DxItem :col-count="4" :col-span="2" item-type="group">
                <DxItem data-field="pocet" />
                <DxItem data-field="jednotka" />
                <DxItem data-field="cena" />
                <DxItem data-field="menaid" :editor-options="{ width: 110 }" /> 
              </DxItem>
              <DxItem :col-count="1" :col-span="1" item-type="group">
                <DxItem data-field="typ" />
              </DxItem>
              <DxItem :col-count="2" :col-span="2" item-type="group">
                <DxItem data-field="AssetNumber" :editor-options="{ width: 500 }" />
              </DxItem>
              
            </DxForm>
          </DxEditing>
          <DxStateStoring
          :enabled="true"
          type="localStorage"
          storage-key="GridObjZas"
         />
         <DxExport
          :enabled="true"
          :formats="['xlsx']"
          :allow-export-selected-data="false"
          :texts="{exportAll: $t('central-ExportVseExcel')}"
          />
          <DxToolbar :visible="stateid==4">
            
            <DxItem  location="after" locate-in-menu="auto">
              <DxButton icon="add" id="PridatZaznam" height="auto" width="auto" :text="$t('central-PridatZaznam')"
                             type="success" styling-mode="contained" @click="AddRowButtonClick" class="actionButtonsPozadavkyResizableB" />
            </DxItem>
            
            
            <DxItem location="before" locate-in-menu="auto" >
                <DxButton icon="revert" id="ObnovitRozlozeni" height="auto" width="auto" :text="$t('central-ObnovitPuvodniRozlozeni')"
                 type="success" styling-mode="outlined" @click="GridLayoutReset" class="actionButtonsPozadavkyResizableB"/>      
            </DxItem>
            <DxItem
              name="exportButton" location="after" locate-in-menu="auto"
            />

            <DxItem
              name="searchPanel" location="after" locate-in-menu="auto"
              :visible="true"
            />
          </DxToolbar>    
          <template #SelectBoxEditor="{ data: cellInfo }">
            <DxSelectBox :disabled="katalogSelect" id="popiseditor" ref="SelectBoxRefKeyEditor" height="auto" :search-enabled="true" @itemClick="KatalogItemClick"
              search-mode="contains" :data-source="KatalogList" display-expr="content" placeholder="" 
              @value-changed="SetValue($event,cellInfo)" @input="SearchKatalog" @focusOut="FocusOutSelectBoxEditor"
              no-data-text="" :value="cellInfo.value" :accept-custom-value="true"
              @customItemCreating="customItemCreating($event)" item-template="itemTemplateSelectBoxEditor" :drop-down-options="{ minWidth: '70vw' }" >
              <template v-slot:itemTemplateSelectBoxEditor="{ data }">
            <span v-bind:title="data.content"> {{ data.content }} </span>
          </template> 
        </DxSelectBox>
              
          </template>
          
          <template #SelectBoxEditorZakazky="{ data: cellInfoZakazky }">
            <DxSelectBox  id="zakazkyeditor" ref="SelectBoxZakazky" height="auto" :search-enabled="true" 
              search-mode="contains" :data-source="zakazky" display-expr="content" placeholder="" @value-changed="SetValueZakazky($event,cellInfoZakazky)"
               :value="cellInfoZakazky.value" :accept-custom-value="true"
              @customItemCreating="customItemCreating($event)"> </DxSelectBox>
          </template>
          <template #SelectBoxInvC="{ data: cellInfoInvC }">
            <DxSelectBox class="invc" width="550px" height="auto" id="invcEditor"  
                :search-enabled="true" :data-source="majetky" display-expr="content" value-expr="invc" placeholder="" :search-timeout="1000" 
                @value-changed="SetValueInvC($event,cellInfoInvC)" @input="SearchInputAssets" noDataText="" :accept-custom-value="true" :value="cellInfoInvC.value"
                @customItemCreating="customItemCreatingAssets($event)">
              </DxSelectBox>
          </template>
          
          <DxColumn :width="60" data-field="Id"  :allow-editing="false" :visible="false"
            :formItem="{ visible: false }" />

          <DxColumn data-field="description" :caption="$t('central-Popis')" edit-cell-template="SelectBoxEditor">
            <DxRequiredRule :message="$t('central-PopisMusiBytVyplnen')" />
          </DxColumn>
          <DxColumn data-field="ProductNumber" width="100px"
            :caption="$t('central-KatC')">
          </DxColumn>
          <DxColumn data-field="typ" width="100px" :caption="$t('central-Zakazka')" edit-cell-template="SelectBoxEditorZakazky">
          </DxColumn>
          <DxColumn data-field="AssetNumber" width="100px" :caption="$t('central-KMajetkuC')" edit-cell-template="SelectBoxInvC">
          </DxColumn>
          <DxColumn data-field="ClassificationTXT" width="100px" :caption=" $t('pozadavky-Specifikace') ">
            </DxColumn>
          <DxColumn width="180px" data-field="specid" :caption="$t('pozadavky-Specifikace')" :visible=" false "
            placeholder="">
            <DxLookup :data-source=" specifikace " display-expr="content" value-expr="id" > </DxLookup>
            <DxRequiredRule :message="$t('central-SpecifikaceMusiBytVyplnena')" />
          </DxColumn>
          <DxColumn format=",##0.00" data-field="pocet" :caption="$t('central-Pocet') " width="90px" data-type="number">
            <DxNumericRule :message="$t('central-PocetMusiBytCislo')" />
            <DxRequiredRule :message="$t('central-PocetMusiBytVyplnen')" />
          </DxColumn>
          <DxColumn width="80px" data-field="jednotka" :caption="$t('central-Jednotka')">
            <DxValidator>
              <DxRequiredRule :message="$t('central-JednotkaMusiBytVyplnena')" />
            </DxValidator>
          </DxColumn>
          <DxColumn format=",##0.00" width="130px" data-field="cena" data-type="number"
            :caption="$t('central-Cena/Jednotka')">             
            <DxNumericRule :message="$t('central-CenaMusiBytCislo')" />
            <DxRequiredRule :message="$t('central-CenaMusiBytVyplnena')" />
            <DxCustomRule :validation-callback="validateNumberPositive" :message="this.$t('Validator.CenaMusiBytKladna')"
                :reevaluate="true" type="custom" />
          </DxColumn>
          <DxColumn :width="60" data-field="menaid" :caption="$t('central-Mena')" placeholder="">
            <DxLookup :data-source="meny" display-expr="content" value-expr="id">
            </DxLookup> 
            <DxRequiredRule :message="$t('central-MenaMusiBytVyplnena')" />
          </DxColumn>
          <DxColumn format=",##0.00" :width="200" data-field="celkem" :allow-editing="false" :caption="$t('central-Celkem')">
            <DxNumericRule :message="$t('central-CelkemMusiBytCislo')" />
          </DxColumn>
          <DxColumn data-field="katalog" :width="60" :allow-editing="false" :visible="false" :formItem="{ visible: true }" />
          <DxSummary>
        <DxTotalItem
          summary-type="count"
          showInColumn="description"
          :display-format="$t('central-PolozekCelkem')+' {0}'"
          cssClass="polozekCelkem"
        />
        <DxTotalItem column="pocet" summary-type="sum" showInColumn="pocet" :display-format="'‎ '" cssClass="sum" />
        <DxTotalItem 
          column="celkem"
          summary-type="sum"
          value-format=",##0.00"
          :display-format="$t('central-SumaCelkem')+' {0}'"
          cssClass="cenaCelkem"
        />
      </DxSummary>
          <DxPaging :enabled="false" />
          <DxGroupPanel :visible="true" :empty-panel-text="$t('central-PretahneteSemProSouhrn')" />
          <DxGrouping :auto-expand-all="true" />
          <DxSorting :ascending-text="$t('central.Sorting.ascendingText')" :clear-text="$t('central.Sorting.clearText')" :descending-text="$t('central.Sorting.descendingText')" />
          <DxSearchPanel :width="300" :visible="true" :placeholder="$t('central-Hledat')" />
        </DxDataGrid>
        </div>
        <div width="100%" class="SpodniMenuDetailPozadavku">
                <DxResponsiveBox single-column-screen="sm" >
              <DxRow :ratio="1" />
              <DxRow :ratio="1" />
              <DxRow :ratio="1" />
              <DxCol :ratio="1" />
              <DxCol :ratio="1" />
              <DxCol :ratio="0.5" />
              <DxCol :ratio="1" />
              <DxCol :ratio="1" />

                <DxItem>
                  <DxLocation :row="0" :col="0" :colspan="2" screen="lg" />
                  <DxLocation :row="0" :col="0" :colspan="2" screen="md" />
                  <DxLocation :row="0" :col="0" :colspan="2" screen="sm" />
                  <DxLocation :row="0" :col="0" :colspan="2" screen="xs" />
                      <div v-if="h" id="ResponsiveBoxItemActionButtons"> 
                          <DxButton icon="add" id="vytvorit" height="auto" width="100%" :text="$t('pozadavky-VytvoreniObjednavky')" :hint="$t('pozadavky-VytvoreniObjednavky')"
                            :use-submit-behavior="true" type="success" styling-mode="contained" @click=" TlacitkoAktiva=false;SuppliersValidate = true"  />
                      </div>
                      
                </DxItem>

                <DxItem>
                  <DxLocation :row="0" :col="3" :colspan="2" screen="lg" />
                  <DxLocation :row="0" :col="3" :colspan="2" screen="md" />
                  <DxLocation :row="0" :col="3" :colspan="2" screen="sm" />
                  <DxLocation :row="0" :col="3" :colspan="2" screen="xs" />
                    <div v-if="h" id="ResponsiveBoxItemActionButtons"> 
                      <DxButton  :hint="$t('polozky-zalozeni-PrejitNaActivaCZ')" icon="cart" :text="$t('polozky-zalozeni-ObjednavkaActiva')"  value="link"
                      width="100%"  height="auto" :disabled="ActivaDisabled" styling-mode="contained" id="activa" :use-submit-behavior="true" type="success" @click="TlacitkoAktiva=true; SuppliersValidate = false "  />
                    </div>
                </DxItem>
                
                <DxItem>
                  <DxLocation :row="1" :col="0" :colspan="5" screen="lg" />
                  <DxLocation :row="1" :col="0" :colspan="5" screen="md" />
                  <DxLocation :row="1" :col="0" :colspan="5" screen="sm" />
                  <DxLocation :row="1" :col="0" :colspan="5" screen="xs" />
                  <DxScrollView direction="horizontal" showScrollbar="always" width="100%" v-if="!h">     
                      <div id="ResponsiveBoxItemActionButtons" v-if="!h">
                        <div class="child actionButtonsPozadavkyMargin" v-for="item in actionsPozadavky" :key="item" id="actionbuttons">
                                <DxButton class="button1 actionButtonsPozadavkyResizableA" height='100%' width="auto" type="default" 
                                    styling-mode="contained" :text="item.nazev" :id="item.id" @click="SetState(item.id)" :class="item.class" :icon="item.icon"
                                    v-show="(item.nazev != 'Schválit') && (item.nazev != 'Vrátit') && (item.nazev != 'Zamítnout')" />
                            </div>
                
                            <div class="child" >
                                    <ChangeOwner :Disabled="false" :RequestID="obj" @owner-changed="setSelectedValue" >  </ChangeOwner>
                                  </div>
                              
                    </div>
                  </DxScrollView>  
                        
                </DxItem>

            </DxResponsiveBox>
            </div>
            
        </form>

      </div>

      
      </div>
    </div>

</template>

<script>

const dataGridRefKey = 'my-data-grid';
const KatalogDataSource = new DataSource({
  store: {
    data: '',//suppliersData,
    type: 'array',
    key: 'id',
  },
});
import ChangeOwner from '@/components/ChangeOwner.vue';
import FundingInstructions from '@/components/FundingInstructions.vue';
import "devextreme/ui/html_editor/converters/markdown";
import { DxNumberBox } from 'devextreme-vue/number-box';
import axios from 'axios'
import notify from 'devextreme/ui/notify';
import DataSource from 'devextreme/data/data_source';
//import { suppliersData } from '@/data.js';
import { isFunction, isNull, isUndefined } from 'lodash';
import Suppliers from '@/components/Suppliers.vue';
import HelpLink from '@/components/HelpLink.vue';
import {
  DxSummary,
  DxTotalItem,
  DxStateStoring,
  DxExport,
  DxToolbar,
  DxSorting
} from 'devextreme-vue/data-grid';
import Attachments from '@/components/Attachments.vue';
import DxScrollView from 'devextreme-vue/scroll-view';

export default {
  name: "ObjZas",

  components: {
    Attachments,
     DxNumberBox,
     DxExport,
     DxSummary,
     DxTotalItem,
     DxStateStoring,
     DxToolbar,
     Suppliers,
     ChangeOwner,
     HelpLink,
     DxScrollView,
     DxSorting,
     FundingInstructions
  },

  data() {
    return {
      TlacitkoAktiva: false,
      SuppliersValidate: false,
      SetOrderError: false,
      checked: false,
      date: null,
      dnyDoDodani: 0,
      typyDodani: [],
      typDodani: [{ "content": '', "id": 1 }], 
      MountingData: false,
      katalogSelect: false,
      katalog: false,
      katalogVybran: false,
      popupAlert: false,
      showModal: false,
      h: true,
      /**/
      stateid: 4,
      pocet: 1,
      novySupplierId: 0,
      PocetKolonek: 3,
      /**/
      katalogDodavatel: "",
      screen: '',
      state: '',
      popupAlertTemplate: '',
      grant: "",
      via: '',
      komentar: '',
      supplier: "",
      spec: "",
      popis: '',
      menaid: '',
      cenaid: '',
      jednotka: 'ks',
      cena: '100',
      typ: 'Zakazka',
      klasifikace: '',
      obj: '',
      invC: '',
      AssetNumber: '',
      ProductNumber: '',
      zakazky: [],
      /**/
      dataGridRefKey,
      GridRows: [],
      KatalogList: [],
      actionsPozadavky: [],
      granty: [],
      vias: [],
      Supplier: [{ content: '', id: -1 }],
      objSupplier: [{ content: '', id: -1 }],
      objGrant: [{ content: '', id: null }],
      currentGrant: null,
      objVia: [{ content: '', id: -1 }],
      meny: [],
      mena: [{ "content": 'CZK', "id": 129 }],
      suppliers: [],
      specifikace: [],
      ActivaDisabled: false,
      majetky: [],
      NotifyError: false,
    }
  },
  computed: {
    dataGrid: function () {
      return this.$refs[dataGridRefKey].instance;
    },
  },
  created(){
    window.MountCount=0
     if (window.localStorage.getItem("pozid") != '' && window.history.state.back!=null) this.h = false
    this.Meny();
    this.Activa();
    this.ListOrderDeliveryTypes();
  },
  mounted() {
    this.$log.debug(window.history.state)
    window.NelzeSchvalovat = true
    this.MountingData=true
    window.this.$log.debug('objednavky mounted')
   // window.localStorage.setItem("refresh-page", 'Objednavka')
    

    if (!this.h) {
      this.obj = window.localStorage.getItem("pozid")
     // this.PolozkyObjednavkyGrid(this.obj)
      this.DisplayRequest();
      this.Prilohy()
    }
    this.LoadBarvyTheme();
  },
  /* unmounted(){
    this.$log.debug('unmounted')
    window.localStorage.setItem("pozid", '')
  }, 
  */
  methods: {
    GetAssetsInit() {
            var asset = new window.Asset(window.channel, window.message);
            var EmpID = JSON.parse(window.localStorage.getItem("user")).EmployeeID.value
            let self = this;
            self.majetky.length = 0;
            asset.GetAsset(EmpID, true, '', 0, 1,
                function (result) {
                    window.this.$log.debug(result);
                    for (var x = 0; x < result.List.value.items.length; x++) {
                            self.majetky[x] = {
                                content: result.List.value.items[x].Id.value + ' ' + result.List.value.items[x].Name.value,
                                invc: result.List.value.items[x].Id.value
                            };
                    }
                 //   e.component.getDataSource().reload();
                }, window.this.ApiError());
        },
    GetAssets(assets) {
            if (assets.component != undefined) {
                var e = assets
                var filter = e.component.option("text")
            }
            var asset = new window.Asset(window.channel, window.message);
            var EmpID = JSON.parse(window.localStorage.getItem("user")).EmployeeID.value

            let self = this;
            self.majetky.length = 0;
            asset.GetAsset(EmpID, true, filter, 0, 1,
                function (result) {
                    window.this.$log.debug(result);
                    for (var x = 0; x < result.List.value.items.length; x++) {
                            self.majetky[x] = {
                                content: result.List.value.items[x].Id.value + ' ' + result.List.value.items[x].Name.value,
                                id: result.List.value.items[x].InternalId.value,
                                invc: result.List.value.items[x].Id.value
                            };
                        }
                    e.component.getDataSource().reload();
                }, window.this.ApiError());
        },
    FocusOutSelectBoxEditor(e) { 
      // tady pozor na to, ze se to uklada na zaklade stringu Potvrdit a Confirm v central-Potvrdit, a Zrušit a Cancel to stejne
      this.$log.debug(e)
      this.$log.debug(e.event.originalEvent.relatedTarget)
      if (e.event.originalEvent.relatedTarget != null && (e.event.originalEvent.relatedTarget.ariaLabel === 'Potvrdit' || e.event.originalEvent.relatedTarget.ariaLabel === 'Confirm')) this.dataGrid.saveEditData();
     if (e.event.originalEvent.relatedTarget != null && (e.event.originalEvent.relatedTarget.ariaLabel === 'Zrušit' || e.event.originalEvent.relatedTarget.ariaLabel === 'Cancel')) this.dataGrid.cancelEditData();  
   //  this.$log.debug(e.event.originalEvent.relatedTarget.classList[1])
    },
    popisEditorValueChanged(e) {
      this.$log.debug('popisEditorValueChanged',e)
      if (e.selectedItem && e.selectedItem.name) {
        e.component.option("hint", e.selectedItem.name);
      } else {
        e.component.option("hint", "");
      }
    },
    LoadBarvyTheme(){
            var elementBarvaTheme = document.getElementsByClassName('dx-theme-accent-as-background-color')[0];
    var computedStyleBarvaTheme = window.getComputedStyle(elementBarvaTheme);
    var FillColorBarvaTheme = computedStyleBarvaTheme.fill;
    var rgbaColorTheme = FillColorBarvaTheme.replace("rgb", "rgba").replace(")", ", 0.25)");
        //console.log('Background Color:', FillColor);
        document.documentElement.style.setProperty('--colorByTheme', FillColorBarvaTheme);
        document.documentElement.style.setProperty('--colorByThemeQuarterOpacity', rgbaColorTheme);
        },
    GridLayoutReset(e) {
      this.$log.debug(e)
      this.$log.debug(this.dataGrid)
      this.dataGrid.state(null);
    },
    AddRowButtonClick(e){
      this.$log.debug(e)
      this.dataGrid.addRow();
    },
    KatalogItemClick(e) {
      this.$log.debug(e)
      //this.ItemContent= e.itemData.content
      
     // e.component.open()
     // this.katalogVybran=true
      //console.log(e)
     // e.component.option('value', e.itemData.content)
     // e.component.repaint()
      //this.dataGrid.cellValue(window.rowIndex, "description", e.itemData.content)
    },
    NewRequestActiva() {
      window.this.$log.debug("Activa");
      
      window.this.$log.debug(this.currentGrant);
      var gen = new window.General(window.channel, window.message);
      gen.GetAPIRequest("Activa", window.localStorage.getItem("session-token"), this.currentGrant,  
        function (result) {
          window.location.href = result.Value.value;
         // window.localStorage.setItem("refresh-page", 'Menu')
        }, window.this.ApiError());

    },
    SelectBoxClick(e) {
      window.this.$log.debug(e)
    },
    SetValueZakazky(e, cellInfo){
      //var self = this;
      window.this.$log.debug(e)
      window.this.$log.debug(cellInfo)
     // window.this.$log.debug(self)
      cellInfo.setValue(e.value);
    },
    SetValueInvC(e, cellInfo){
      //var self = this;
      window.this.$log.debug(e)
      window.this.$log.debug(cellInfo)
     // window.this.$log.debug(self)
      cellInfo.setValue(e.value);
    },
    SetValue(e, cellInfo) {
      var self = this;
      window.this.$log.debug(e)
      window.rowIndex = cellInfo.rowIndex
      cellInfo.setValue(e.value);
    if (!isNull(e.value)){ 
      if (!isUndefined(e.value.cena)) {
        self.katalogVybran = true 
      //  e.component.option('value', e.value.supplier )
        self.katalogDodavatel = e.value.supplier
        window.this.$log.debug(self.katalogDodavatel)
        if(self.objSupplier[0].content!=self.katalogDodavatel)
        self.objSupplier[0] = { content: self.katalogDodavatel, id: 0 }
        window.this.$log.debug('Dodavatel vybran.')
        //e.value.cena=
        /* tato cast kodu celkem trva jak meni ten grid */
     //   this.dataGrid.cellValue(window.rowIndex, "description", e.value)
        cellInfo.component.cellValue(cellInfo.rowIndex, "cena", (e.value.cena))
        cellInfo.component.cellValue(cellInfo.rowIndex, "jednotka", e.value.jednotka)
        cellInfo.component.cellValue(cellInfo.rowIndex, "ProductNumber", e.value.katcis)
        cellInfo.component.cellValue(cellInfo.rowIndex, "celkem", ((e.value.cena * cellInfo.data.pocet).toFixed(2)))
        cellInfo.component.cellValue(cellInfo.rowIndex, "menaid", 129)
        cellInfo.component.cellValue(cellInfo.rowIndex, "specid", self.specifikace[0].id)
        cellInfo.component.cellValue(cellInfo.rowIndex, "katalog", true)
   
        window.this.$log.debug(cellInfo.component.columnOption("celkem"))
        cellInfo.component.columnOption("cena", "allowEditing", false)
        cellInfo.component.columnOption("menaid", "allowEditing", false)
        cellInfo.component.columnOption("jednotka", "allowEditing", false)
        cellInfo.component.columnOption("ProductNumber", "allowEditing", false)
        cellInfo.component.columnOption("specid", "allowEditing", false)
        /* tato cast kodu celkem trva jak meni ten grid */
        //cellInfo.component.cellValue(cellInfo.rowIndex, "celkem", ((e.value.cena * cellInfo.data.pocet).toFixed(2)))
        // cellInfo.component.cellValue(cellInfo.rowIndex, "cena", (e.value.cena))
       // self.katalog = true;
        window.this.$log.debug(cellInfo.data)
       
      }
      if (isUndefined(e.value.supplier)) {
        cellInfo.component.cellValue(cellInfo.rowIndex, "katalog", false)
        self.katalog = false
      }

    }
    },
    log(log) {
      window.this.$log.debug(log)
    },

    ListKatalog(search, dodavatel, e) {
      var self = this;
      self.KatalogList.length = 0
      var orders = new window.Orders(window.channel, window.message);
      window.this.$log.debug(search)
      window.this.$log.debug(dodavatel)
      var jazyk = window.localStorage.getItem("jazyk")
      if (self.katalogVybran == true)
        dodavatel = self.katalogDodavatel
      window.this.$log.debug(dodavatel)
      if (isNull(search) || search == '') search = ' '
      window.this.$log.debug(search)
     // console.log(this.dataGrid.getVisibleRows().length)
        orders.KatalogList(search, dodavatel, 30,
          function (result) {
            window.this.$log.debug(result);
            for (var x = 0; x < result.items.length; x++) {

              //  window.this.$log.debug(result.List.value.items[x]);
              // var i=self.KatalogList.length;             

              self.KatalogList[x] = {
                content: (jazyk === 'en') ? result.items[x].nazeven.value : result.items[x].nazev.value,
                id: result.items[x].id.value,
                supplier: result.items[x].dodavatel.value,
                jednotka: result.items[x].jednotka.value,
                katcis: result.items[x].katcis.value,
                cena: result.items[x].cenaj.value,
              };
            }
            window.this.$log.debug('self.KatalogList',self.KatalogList)
            window.this.$log.debug('e.component',e.component)
            window.this.$log.debug(e.component.getDataSource)
            if (!isNull(e.component.getDataSource()))
              e.component.getDataSource().reload();
           // console.log(e.component.option())
          }, window.this.ApiError());
    
    },
    validateNumberPositive(e) {
                    this.$log.debug(e.value+' celkova cena')
                    const isValid = e.value >= 0;
                    //this.validationStatus = isValid ? 'valid' : 'invalid';
                    return isValid;
        },
    ListKatalogv2(search, dodavatel) {
      var self = this;
      self.KatalogList.length = 0
      var orders = new window.Orders(window.channel, window.message);
      window.this.$log.debug(search)
      window.this.$log.debug(dodavatel)
      var jazyk = window.localStorage.getItem("jazyk")
      if (self.katalogVybran == true)
        dodavatel = self.katalogDodavatel
      window.this.$log.debug(dodavatel)
      if (isNull(search) || search == '') search = ' '
      window.this.$log.debug(search)
        orders.KatalogList(search, dodavatel, 30,
          function (result) {
            window.this.$log.debug(result);
            for (var x = 0; x < result.items.length; x++) {

              //  window.this.$log.debug(result.List.value.items[x]);
              // var i=self.KatalogList.length;             

              self.KatalogList[x] = {
                content: (jazyk === 'en') ? result.items[x].nazeven.value : result.items[x].nazev.value,
                id: result.items[x].id.value,
                supplier: result.items[x].dodavatel.value,
                jednotka: result.items[x].jednotka.value,
                katcis: result.items[x].katcis.value,
                cena: result.items[x].cenaj.value,
              };
            }
          }, window.this.ApiError());
    },
    FileDelete(FileID) {
      var self = this;
      var gen = new window.General(window.channel, window.message);
      gen.FileDelete(1, self.obj, FileID,
        function (result) {
          window.this.$log.debug(result);
          self.Prilohy();
        }, window.this.ApiError());

    },
    async downloadItem(url, name, suffix) {
      window.this.$log.debug(suffix)
      suffix = '.pdf'
      axios.get(url, { headers: { 'Accept': 'application/pdf' }, responseType: 'blob' })
        .then(function (response) {
          switch (suffix) {
            case '.pdf': {
              window.this.$log.debug(response)
              const blob = new Blob([response.data], { type: 'application/' + suffix });
              const link = document.createElement('a');
              link.href = window.URL.createObjectURL(blob);
              link.download = name;
              link.click();
              window.this.$log.debug(link)
              window.URL.revokeObjectURL(link.href);
              break;
            }
          }
        })
        .catch(function (error) {
          window.this.$log.debug(error)
          window.this.$log.debug(JSON.stringify(error))
        })
    },
    GetHash(FileName, FileID) {
      let self = this;
      var gen = new window.General(window.channel, window.message);
      gen.GetFileHash(FileID,

        function (result) {
          window.this.$log.debug(result);
          self.hash = window.URL1+'/js/download/' + result.Value.value;
          window.this.$log.debug(self.hash + " " + FileName)
          self.downloadItem(self.hash, FileName);

        }, window.this.ApiError());
    },
    FileUpload(file) {
      window.this.$log.debug(file)
      var self = this;
      var gen = new window.General(window.channel, window.message);
      var reader = new FileReader();
      reader.onload = (function (theFile) {
        window.this.$log.debug(theFile.name)
        return function (e) {
          gen.FileUpload(1, self.obj, theFile.name,
            e.target.result,
            function (result) {
              window.this.$log.debug(result);
              self.Prilohy()
            }, window.this.ApiError());
        }
      })(file);
      reader.readAsBinaryString(file);

    },
    SetNotifyError(timeout){
      this.NotifyError=true;
      setTimeout(() => {
        this.NotifyError=false;
        }, timeout);
    },
    SetState(stav, alert) {
      window.this.$log.debug(alert + ' alert<<<??>')
      var self = this;
      var wh = new window.WorkFlow(window.channel, window.message);

      if (stav == -1) {
        window.this.$log.debug('konec')
        this.popupAlert = true
        this.popupAlertTemplate = 'SmazatPoz'
        return 1;
      }
      if (alert == true) {
        stav = -1
      }
      if (window.NelzeSchvalovat && stav == 0)
      {
        notify({
          message: self.$t('pozadavky-NelzeUvestPozadavekKeSchvaleni'),
          position: {
            my: 'center top',
            at: 'center top',

          },
        }, 'error', 5000); //'info' - barva templatu, 'success', 'warning', 'error'
        self.SetNotifyError(5000);
      }
      else
      {
        wh.SetState(self.obj, stav, '',
          function (result) {
            window.this.$log.debug(result);
            if (result.ErrorNum.value != 0) {
              notify({
                message: result.ErrorTextCZ.value,
                position: {
                  my: 'center top',
                  at: 'center top',

                },
              }, 'error', 5000); //'info' - barva templatu, 'success', 'warning', 'error'
              self.SetNotifyError(5000);
            }
            else {
              var stavText;
              switch (stav) {
                case -1: stavText = self.$t('pozadavky-StavZrusen')
                  break;
                case 0: stavText = self.$t('pozadavky-StavKeSchvaleni')
                  break;
                case 4: stavText = self.$t('pozadavky-StavUlozeny')
                  break;
              }
              notify({
                message: self.$t('pozadavky-UvedenDoStavu') + stavText + '".',
                position: {
                  my: 'center top',
                  at: 'center top',
                },
              }, 'success', 5000);
              self.DisplayRequest();
              if (result.ErrorNum.value == 0 && (stav == -1 || stav == 0 || stav == 4))
                self.$router.push('/Pozadavky?Vypis=0')
            }
          }, window.this.ApiError());
       }
    },
    DisplayRequest() {
      window.this.$log.debug('display request')
      let self = this;
      self.actionsPozadavky.length = 0;
      var wh = new window.WorkFlow(window.channel, window.message);
      window.this.$log.debug(self.actionsPozadavky);
      wh.GetRequest(self.obj,
        function (result2) {
          window.this.$log.debug(result2);
          self.state = result2.ReqStateText.value;
          self.stateid = result2.ReqState.value;
          for (var x = 0; x < result2.Actions.value.items.length; x++) {
            var id = result2.Actions.value.items[x].Id.value;
                        var colorClass = id === 0 ? "buttonKeSchvaleni" : (id === 4 ? "buttonUlozitProUpravy" : (id === -1 ? "buttonSmazat" : "buttonDefault"));
                        var iconName = id === 0 ? "upload" : (id === 4 ? "floppy" : (id === -1 ? "trash" : ""));
                        self.actionsPozadavky[self.actionsPozadavky.length] = {
                            nazev: result2.Actions.value.items[x].Value.value,
                            id: result2.Actions.value.items[x].Id.value,
                            class: colorClass,
                            icon: iconName,
                            pozid: self.obj
            };
          }
        }, window.this.ApiError()); // window.this.$log.debug('chyba')); //
    },

    customItemCreating(data) {
      window.this.$log.debug(data)         
          if (!data.text) {
            data.customItem = null;

            return;
          }
          
            const newItem = {
              content: data.text,
              detail:  data.text
            //   id: 0,
            };
            data.customItem = KatalogDataSource
              .store()
              .insert(newItem)
              .then(() => KatalogDataSource.load())
              .then(() => newItem)
              .catch((error) => {
                throw error;
              });

          var cellvalue = { content: data.text, id: 0 }
          if(data.element.id=='popiseditor') this.dataGrid.cellValue(window.rowIndex, "description", cellvalue)
          else this.zakazky[this.zakazky.length] = { content: data.text, id: 0, detail: data.text }
          //this.dataGrid.saveEditData();
       //   this.dataGrid.closeEditCell();
          
            
    },
    onFormSubmit(e) {
      e.preventDefault();
      this.$log.debug('form submit')
      this.$log.debug(this.objSupplier[0].content)
      this.$log.debug(e)
      
      window.this.$log.debug(e.submitter.attributes[0].ownerDocument.activeElement.id)
      window.this.$log.debug(e)
      window.this.$log.debug(this.currentGrant)
      if (this.objGrant[0].id < 0) {
        notify({
        message: this.$t('pozadavky-NemateOpravneniObjednavat'),
        position: {
          my: 'center top',
          at: 'center top',
        },
      }, 'error', 5000);
        self.SetNotifyError(5000);
      }
      

      if (e.submitter.attributes[0].ownerDocument.activeElement.id == "vytvorit" && this.currentGrant>=0 && this.Supplier[0].content!='') {
        notify({
          message: this.$t('pozadavky-ObjednavkaZalozena'),
          position: {
            my: 'center top',
            at: 'center top',
          },
        }, 'success', 3000);
        this.ListKatalogv2("", "")
        this.NewOrder("vytvorit")
      }
      else if (e.submitter.attributes[0].ownerDocument.activeElement.id == "upravit" && this.currentGrant >= 0) {
      /*  notify({
          message: this.$t('pozadavky-ObjednavkaZmenyUlozeny'),
          position: {
            my: 'center top',
            at: 'center top',
          },
        }, 'success', 3000); */
        
        this.NewOrder("upravit");
      }
      else if (e.submitter.attributes[0].ownerDocument.activeElement.id == "activa" && this.currentGrant > 0) {
        notify({
          message: this.$t('pozadavky-PresmerovaniActiva'),
          position: {
            my: 'center top',
            at: 'center top',
          },
        }, 'success', 3000);
        this.NewRequestActiva();
      }

      
    },
    InitNewRow(e) {
      var isEditing = this.dataGrid.hasEditData()
      this.$log.debug(isEditing)
      this.GetAssetsInit()
      this.ListOrderNumbers();
     // this.katalog = false
      this.katalogSelect = false;
      window.this.$log.debug('new row')
      window.rowIndex = e.component.getRowIndexByKey(e.key)
      if(this.katalogVybran)
      this.ListKatalogv2("", "")
      window.this.$log.debug(e)
      //  e.component.focus();
      if (isUndefined(e.data.pocet)) {
        e.component.columnOption("cena", "allowEditing", true)
        e.component.columnOption("menaid", "allowEditing", true)
        e.component.columnOption("jednotka", "allowEditing", true)
        e.component.columnOption("ProductNumber", "allowEditing", true)
        e.component.columnOption("specid", "allowEditing", true)
        e.data.pocet = 1;
        e.data.jednotka = 'ks';
        e.data.specid = this.specifikace[0].id
        e.data.menaid = this.meny[0].id;
      }
      //e.data.cena = e.data.cena.toFixed(2)
      if (!isUndefined(e.data.cena)) {
        if (e.data.katalog) {
         // this.katalog = true
          e.component.columnOption("cena", "allowEditing", false)         
          e.component.columnOption("jednotka", "allowEditing", false)
          e.component.columnOption("ProductNumber", "allowEditing", false)
          e.component.columnOption("menaid", "allowEditing", false)
          e.component.columnOption("specid", "allowEditing", false)
          this.katalogSelect = true;
        }
        if (isFunction(e.data.pocet.toFixed)) e.data.pocet = e.data.pocet.toFixed(2)
        if (isFunction(e.data.cena.toFixed)) e.data.cena = e.data.cena.toFixed(2)
        if (isFunction(e.data.celkem.toFixed)) e.data.celkem = e.data.celkem.toFixed(2)
      }
    },
    DeleteOrderItem(id) {
      var self = this;
      var wf = new window.WorkFlow(window.channel, window.message);
      wf.DeleteOrderItem(id,
        function (result) {
          window.this.$log.debug(result);
          if (result.ErrorNum.value != 0) {
            notify({
              message: result.ErrorTextCZ.value,
              position: {
                my: 'center top',
                at: 'center top',

              },
            }, 'error', 5000); //'info' - barva templatu, 'success', 'warning', 'error'
            self.SetNotifyError(5000);
          }
          else self.katalogVybran=false
          self.PolozkyObjednavkyGrid(self.obj)
        }, window.this.ApiError()); // window.this.$log.debug('chyba')); //
    },
    GridEditting(data) {   
      var self = this;
      this.$log.debug(self.katalogDodavatel)
      this.$log.debug(data)
      var mena;
      var specifikace;
      var typ;
      if (data.changes.length != 0) {
        if (!isUndefined(data.changes[0].data)){ 
        if (data.changes[0].data.katalog != undefined) self.katalog = data.changes[0].data.katalog;
          typ=data.changes[0].data.typ
          if (!isUndefined(data.changes[0].data.typ) && !isUndefined(data.changes[0].data.typ.detail)) typ=data.changes[0].data.typ.detail
          else {
            for (let i = 0; i < self.zakazky.length; i++) {
                const zakazka = self.zakazky[i];  
                if (zakazka.content === data.changes[0].data.typ) {
                    self.$log.debug('NALEZENO')
                    typ = zakazka.detail;
                    break;
                }
            }
          }

        }
        if (!isUndefined(data.changes[0].data) && !isUndefined(data.changes[0].data.description.supplier) && self.katalogVybran == true) {
          //this.katalogVybran = true
          self.katalogDodavatel = data.changes[0].data.description.supplier
          self.objSupplier[0] = { content: self.katalogDodavatel, id: 0 }
          self.suppliers[0] = { content: self.katalogDodavatel, id: 0 }
          window.this.$log.debug(data.changes[0].data.description.content + ' DESCRIPTION')
          
          self.NewOrder("upravit");
        }
        
        switch (data.changes[0].type) {

          case 'insert':
            mena = data.changes[0].data.menaid
            specifikace = data.changes[0].data.specid
            window.this.$log.debug("Přidání nové položky")
            this.NewOrderItem(this.obj, 0, data.changes[0].data.description.content, data.changes[0].data.ProductNumber, data.changes[0].data.pocet, data.changes[0].data.jednotka,
              data.changes[0].data.cena, mena, typ, specifikace, data.changes[0].data.AssetNumber)
            break;
          case 'update':
            var description
            mena = data.changes[0].data.menaid
            specifikace = data.changes[0].data.specid
            if (isUndefined(data.changes[0].data.description.content)) description = data.changes[0].data.description
            else description = data.changes[0].data.description.content
            window.this.$log.debug(data.changes[0].data.description.content + ' DESCRIPTION UPDATE')
            window.this.$log.debug(data.changes[0].data.description + ' DESCRIPTION UPDATE2')
            this.NewOrderItem(this.obj, data.changes[0].key, description, data.changes[0].data.ProductNumber, data.changes[0].data.pocet, data.changes[0].data.jednotka,
              data.changes[0].data.cena, mena, typ, specifikace, data.changes[0].data.AssetNumber)
            break;
          case 'remove':
            window.this.$log.debug("remove")
            window.this.$log.debug(data.changes[0].key)
            this.DeleteOrderItem(data.changes[0].key)
            break;

          //    default:
          //      break;

        }
      }
    },
    UpdateItem(data) {
      window.this.$log.debug('update')
      window.this.$log.debug(data)
      if (data.changes[0].type == 'update') window.this.$log.debug("Přidání nové položky")
    },
    RemoveItem(data) {
      window.this.$log.debug('remove')
      window.this.$log.debug(data)
      if (data.changes[0].type == 'remove') window.this.$log.debug("Přidání nové položky")
    },
    RefreshDataGrid() { 
      this.$log.debug('refreshdatagrid')
      this.$log.debug(this.dataGrid)
      this.dataGrid.getDataSource().reload()
      this.dataGrid.refresh()
    },

    startEdit(e) {
      // window.this.$log.debug(e)
      window.Index = e.rowIndex
      e.component.editRow(e.rowIndex);
    },
    setSelectedValue(e) {
      window.MountCount++
      window.this.$log.debug(e);
      window.this.$log.debug(window.MountCount + 'MountCount');
      window.this.$log.debug(this.PocetKolonek + 'Dokolika');
      this.$log.debug(e.element.id)
      if (this.h || window.MountCount == this.PocetKolonek) this.MountingData=false // tohle neni uplne dobry napad delat
      if(!this.MountingData){      
        if(!isUndefined(e.value) || e.element.id== 'AnySupplierButton'){
          switch (e.element.id) {
            case '1':
              this.objVia[0].id = e.value
             // this.NewOrder('upravit')
              //e.component.reset()
              break;
            case '12':            
             /* 
              if (e.value === 2381) {
                // Ponechat původní hodnotu vizuálně
                e.component.option('value', e.previousValue);
              } else {
                this.currentGrant = e.value
              }
              */
              this.currentGrant = e.value
              this.Specifikace()
              this.ListOrderNumbers()
           //   this.PolozkyObjednavkyGrid(this.obj)
              //window.this.$log.debug(this.objGrant[0].id)
            //  this.NewOrder('upravit')
              break;
            case '3':
              break;
              
              case 'suppliers':
             this.Supplier[0]=e.value
              break;
            case '4':
             // this.NewOrder('upravit')
              //this.komentar = e.value
              break;
            case '6':
              window.this.$log.debug('jop')
              // e.component.option("text")=e.value.content
              //this.komentar = e.value
              break;
            case '7':
              window.this.$log.debug('jop')
              // e.component.option("text")=e.value.content
              this.typDodani[0].id = e.value
              break;
            case '8':
              window.this.$log.debug('jop')
              break;
            case '9':
              window.this.$log.debug('jop')
              break;
            case '13':
              var now = new Date()
              if (e.value.getDate() != now.getDate())
                this.dnyDoDodani = parseInt((e.value - now) / (1000 * 60 * 60 * 24)) + 1
              else this.dnyDoDodani = 0
              window.this.$log.debug('jop')
              // e.component.option("text")=e.value.content
              //this.komentar = e.value
              break;
              case 'ownerchanged':
              this.$router.push('Pozadavky')
              return 1;
            case 'AnySupplierButton':
              this.objSupplier[0] = { content: 'Libovolný dodavatel', id: 0 };
              this.Supplier[0] = { content: 'Libovolný dodavatel', id: 0 };
              this.NewOrder('upravit')
              break;
          }
          if (!isUndefined(e.value) && !isNull(e.value) && !this.SetOrderError)
          this.NewOrder('upravit')
        }
        this.SetOrderError=false
      }

    },
    ClearValue(e) {
      window.this.$log.debug(e)
      if (e.component.option("text") != '')
        e.component.reset()
    },
    PolozkyObjednavkyGrid(id) {
      let self = this;
      let self2 = this.GridRows;
      self2.length = 0;
      var wf = new window.WorkFlow(window.channel, window.message);
      wf.GetOrder(id,
        function (result) {
          window.this.$log.debug(result);
          if (result.Items.value.items.length < 1) window.NelzeSchvalovat = true
          else window.NelzeSchvalovat = false
          for (var x = 0; x < result.Items.value.items.length; x++) {
            if (!self.katalogVybran) { 
              if (result.Items.value.items[x].Katalog.value == true)
              self.katalogVybran=true
            }
            //  window.this.$log.debug(result.List.value.items[x]);
            var i = self2.length;
            if (result.Items.value.items[x].Currency.value == 0) result.Items.value.items[x].CurrencyTXT.value = 'CZK'
            self2[i] = {
              description: result.Items.value.items[x].Description.value,
              typ: result.Items.value.items[x].Order.value,
              specid: result.Items.value.items[x].Classification.value,
              ClassificationTXT: result.Items.value.items[x].ClassificationTXT.value,
              jednotka: result.Items.value.items[x].MeasureUnit.value,
              pocet: result.Items.value.items[x].Amount.value,
              menaid: result.Items.value.items[x].Currency.value,
              cena: (result.Items.value.items[x].UnitPrice.value),
              celkem: (result.Items.value.items[x].TotalCZK.value),
              //cena: (result.Items.value.items[x].UnitPrice.value).toLocaleString('cs-CZ'),
              // celkem: (result.Items.value.items[x].Amount.value * result.Items.value.items[x].UnitPrice.value).toLocaleString('cs-CZ'),
              AssetNumber: result.Items.value.items[x].AssetNumber.value,
              ProductNumber: result.Items.value.items[x].ProductNum.value,
              Id: result.Items.value.items[x].Id.value,
              katalog: result.Items.value.items[x].Katalog.value,
            };
            
          } 
          self.dnyDoDodani = result.DateOfDelivery.value
          if (self.dnyDoDodani > 0) {
            self.checked = true
            self.PocetKolonek++
          }
          self.typDodani[0].id = result.StateOfDelivery.value
         // if (self.typDodani[0].id != 0) self.PocetKolonek++
          
          if (result.Items.value.items.length!=0)
          self.objGrant[0].content = result.Request.value.FundingTXT.value
          self.objGrant[0].id = result.Request.value.Funding.value
          self.currentGrant= result.Request.value.Funding.value
          self.Specifikace()
         // if (result.Request.value.Funding.value > 0) self.PocetKolonek++ //todo pro delivery type
          self.objVia[0].content = result.Request.value.ReqViaText.value
          self.objVia[0].id = result.Request.value.ReqVia.value
          window.this.$log.debug(self.objVia[0].id + ' obj via')
          self.komentar = result.Request.value.Comment.value
          if (self.komentar != '') self.PocetKolonek++

          self.suppliers.length = 0;
          i = self.suppliers.length;
          self.suppliers[i] = {
            content: result.SupplierTXT.value,
            id: result.Supplier.value,
          };
          self.objSupplier[0] = self.suppliers[0]
          if (result.SupplierTXT.value != '' && self.katalogVybran == true && result.Items.value.items.length != 0) {
            self.katalogDodavatel = result.SupplierTXT.value
            self.ListKatalogv2("", "")
          }
          if (result.Items.value.items.length == 0) {
            self.katalog = false
            self.katalogVybran = true;
            self.polozky = false
          }
          else self.katalog = true
          self.RefreshDataGrid()
        }, window.this.ApiError());
    },
    open() {
      window.loader = this.$loading.show({
        loader: 'dots'
      });
    },
    hide() {
      window.loader.hide()
    },

    SearchInput(e) {
      let self = this;
      clearTimeout(this.timer)
      this.timer = setTimeout(() => {
        self.ListAddr(e)
      }, 1000)

    },
    SearchInputAssets(e) {
            let self = this;
            clearTimeout(this.timer)
            this.timer = setTimeout(() => {
                self.GetAssets(e)  
            }, 1000)

        },
        customItemCreatingAssets(data) {

      window.this.$log.debug(data)
      window.this.$log.debug(data.text + ' text')
      if (!data.text) {
          data.customItem = null;

          return;
      }

      // const itemIds = suppliersData.map((item) => item.ID);
      //const incrementedId = Math.max.apply(null, itemIds) + 1;
            const majetekDataSource = new DataSource({
              store: {
                  type: 'array',
                  key: 'id',
                  data: [] 
              }
          });
          const newItem = {
              content: data.text,
              invc: data.text
              // id: incrementedId,
          };
          data.customItem = majetekDataSource
              .store()
              .insert(newItem)
              .then(() => majetekDataSource.load())
              .then(() => newItem)
              .catch((error) => {
                  throw error;
              });
     // this.majetek[0] = { content: data.text, invc: data.text, event: 'itemCreating' }

      },
    SearchKatalog(e) {
      window.this.$log.debug(e)
      // window.this.$log.debug(e.component.option("text"))
      let self = this;
      clearTimeout(this.timer)           
      //console.log(this.dataGrid.getVisibleRows().length)
        this.timer = setTimeout(() => {
          if (isUndefined(self.objSupplier) || isNull(self.objSupplier) ||
            isUndefined(self.objSupplier.content) || self.objSupplier == '' || self.katalogVybran == false)
            self.ListKatalog(e.component.option("text"), '', e)   
          else self.ListKatalog(e.component.option("text"), self.objSupplier[0].content, e)
        }, 1000)
    },
    ListVias() {
      let self = this;
      self.vias.length = 0;
      var gen = new window.General(window.channel, window.message);
      gen.ListLookup('REQUEST_VIAS[1]', '', 1, 50,
        function (result) {
           window.this.$log.debug(result);
          for (var x = 0; x < result.List.value.items.length; x++) {
            //  window.this.$log.debug(result.List.value.items[x]);
            self.vias[x] = {
              content: result.List.value.items[x].Value.value,
              id: result.List.value.items[x].Id.value,
            };
          }
        if(self.h)
        self.objVia[0] = self.vias[0]
        else
        self.PolozkyObjednavkyGrid(self.obj)
        }, window.this.ApiError());

    },
    NewOrder(param) {
     // console.log(window.this)
      let self = this;
      var wf = new window.WorkFlow(window.channel, window.message);
      var supp;
      this.SetOrderError = false
      this.$log.debug(this.Supplier[0])
      window.this.$log.debug(this.objSupplier[0])
      if (isNull(this.Supplier[0])) {
        this.Supplier = [{ content: '' }]
      }
      if (!isUndefined(this.Supplier[0].id))
        supp = this.Supplier[0].id;
      else supp = undefined
      var supptext = ''
      window.this.$log.debug(this.objSupplier)
      if (supp == undefined || supp == null || supp == 0) {
        supptext = this.Supplier[0].content;
        supp = 0;
      }
      window.this.$log.debug(supptext + ' supptext ' + supp)
      window.this.$log.debug(self.typDodani[0].id)
      var dny, typDodani
      if (self.checked == false) {
        dny = 0
        typDodani = 175794
      }
      else {
        dny = self.dnyDoDodani
        typDodani = self.typDodani[0].id
      }
      if (dny == undefined || dny == null) dny = 0
      if (param == 'vytvorit') {
        window.this.$log.debug(this.objSupplier + 'objSupplier')

        window.this.$log.debug(supp)
        window.this.$log.debug(supptext)
       
        wf.SetOrder(0, self.objVia[0].id, self.currentGrant, supp, supptext, self.komentar, dny, typDodani ,
          function (result) {
            window.this.$log.debug(result);
            self.obj = result.Value.value
            window.localStorage.setItem("pozid", result.Value.value)
            self.h = false;
            self.DisplayRequest();
            self.dataGrid.addRow()
          }, window.this.ApiError());

      }
      else {
        var isEditing = this.dataGrid.hasEditData()
        this.$log.debug(isEditing)
        if (!this.h ) {
          wf.SetOrder(self.obj, self.objVia[0].id, self.currentGrant, supp, supptext, self.komentar, dny, typDodani,
            function (result) {
              
            if (result.Error.value.ErrorNum.value == 0 && !self.NotifyError){ 
            notify({
              message: self.$t('pozadavky-ObjednavkaZmenyUlozeny'),
              position: {
                my: 'center top',
                at: 'center top',
              },
            }, 'success', 3000);
            window.this.$log.debug(result);
          //    self.DisplayRequest();
            }
            else {
              self.$log.debug(result)
              notify({
                message: result.Error.value.ErrorTextCZ.value,
                position: {
                  my: 'center top',
                  at: 'center top',
                },
              }, 'error', 3000);
              self.SetOrderError=true
              self.SetNotifyError(3000);
              //self.PolozkyObjednavkyGrid(self.obj)
            }
          }, window.this.ApiError());
        }
      }
    },
    ListOrderDeliveryTypes() {
      let self = this;
      self.typyDodani.length = 0;
      var gen = new window.General(window.channel, window.message);
      gen.ListLookup('ObjDobaDodani', '', 1, 50,
        function (result) {
          window.this.$log.debug(result);
          for (var x = 0; x < result.List.value.items.length; x++) {

            //  window.this.$log.debug(result.List.value.items[x]);
            self.typyDodani[x] = {
              content: result.List.value.items[x].Value.value,
              id: result.List.value.items[x].Id.value,
            };
          }
          if (self.h)
            self.typDodani[0] = self.typyDodani[0]
          self.ListFunding()
        }, window.this.ApiError());
    },
    ListFunding() {
      let self = this;
      self.granty.length = 0;
      var wf = new window.WorkFlow(window.channel, window.message);
      wf.ListFunding(1, 3,
        function (result) {
          window.this.$log.debug(result);
          for (var x = 0; x < result.List.value.items.length; x++) {
            //  window.this.$log.debug(result.List.value.items[x]);
            self.granty[x] = {
              content: result.List.value.items[x].Text.value + ' ' + result.List.value.items[x].Name.value,
              //  number: result.List.value.items[x].Text.value,
              id: result.List.value.items[x].ID.value,
            };
          }
          self.granty[self.granty.length] = {
            content: self.$t('central-GrantNevybran'),
            id: 0,
          }       
          if(self.h && result.List.value.items.length == 1 )
             self.currentGrant = self.granty[0].id;
        //  self.Specifikace()
          self.ListVias()
        }, window.this.ApiError());
    },
    ListOrderNumbers() {
      this.$log.debug('zakazky')
      let self = this;
      self.zakazky.length = 0;
      var gen = new window.General(window.channel, window.message);
      gen.ListOrderNumbers(this.currentGrant,
        function (result) {
          window.this.$log.debug(result);
          for (var x = 0; x < result.List.value.items.length; x++) {
            //  window.this.$log.debug(result.List.value.items[x]);
            self.zakazky[x] = {
              content: result.List.value.items[x].Value.value+' ('+ result.List.value.items[x].Detail.value+')',
              detail: result.List.value.items[x].Detail.value,
              id: result.List.value.items[x].Id.value,
            };
          }
          //self.$log.debug(self.$refs.SelectBoxZakazky);
          self.dataGrid.refresh()
          if(self.$refs.SelectBoxZakazky.instance!=undefined)
          self.$refs.SelectBoxZakazky.instance.getDataSource().reload();     
        }, window.this.ApiError());
    },
    ListAddr(supp) {

      let self = this;
      if (supp.component != undefined) {
        var e = supp
        supp = e.component.option("text")
      }
      var gen = new window.General(window.channel, window.message);
      window.this.$log.debug(supp)
      self.suppliers.length = 0;
      gen.ListAddr(supp, 1, 25,
        function (result) {
          //    window.this.$log.debug(result);
          for (var x = 0; x < result.List.value.items.length; x++) {

            //  window.this.$log.debug(result.List.value.items[x]);
            var i = self.suppliers.length;
            self.suppliers[i] = {
              content: result.List.value.items[x].Value.value,
              id: result.List.value.items[x].Id.value,
              event: 'listAddr'
            };
          }
          e.component.getDataSource().reload();
        }, window.this.ApiError());
    },
    Meny() {
      let self = this;
      self.meny.length = 0
      var gen = new window.General(window.channel, window.message);
      gen.ListLookup('{Meny(IDENT=ZAKLAD)}', '', 1, 25,
        function (result) {
          //  window.this.$log.debug(result);
          for (var x = 0; x < result.List.value.items.length; x++) {

            //  window.this.$log.debug(result.List.value.items[x]);
            var i = self.meny.length;

            self.meny[i] = {
              content: result.List.value.items[x].Value.value,
              id: result.List.value.items[x].Id.value,
            };
          }
        }, window.this.ApiError());
    },
    Activa() {
      let self = this;
      var gen = new window.General(window.channel, window.message);
      gen.GetGlobalVariable('Activa', 
        function (result) {
          window.this.$log.debug('Activa');
            window.this.$log.debug(result);
            self.ActivaDisabled=(result.Value.value=='0');
        }, window.this.ApiError());
    },
    Specifikace() {
      let self = this;
      var gen = new window.General(window.channel, window.message);
      self.specifikace.length = 0
      this.$log.debug(this.currentGrant)
      gen.ListLookup(`ObjPolZaraz[FUNDING_ID=${this.currentGrant}]`, '', 1, 25,
        function (result) {
            window.this.$log.debug(result);
          for (var x = 0; x < result.List.value.items.length; x++) {

            //  window.this.$log.debug(result.List.value.items[x]);
            var i = self.specifikace.length;

            self.specifikace[i] = {
              content: result.List.value.items[x].Value.value,
              id: result.List.value.items[x].Id.value,
            };
          }
          self.$log.debug('Specif reloaded')

        }, window.this.ApiError());
    },
    NewOrderItem(requestId, id, popis, productNumber, pocet, jednotka, cena, menaId, typ, specId, assetNumber) {
      var wf = new window.WorkFlow(window.channel, window.message);
      let self = this;
      window.this.$log.debug(specId + ' spec id')
      /*
      
      window.this.$log.debug(self.popis)
      window.this.$log.debug(self.pocet)
      window.this.$log.debug(self.jednotka)
      window.this.$log.debug(self.cena)
      window.this.$log.debug(self.mena.id)
      window.this.$log.debug(self.typ)
      window.this.$log.debug(self.spec.id)
      */
      window.this.$log.debug(menaId + ' mena')
      wf.SetOrderItem(requestId, id, popis, productNumber, pocet, jednotka, cena, menaId, typ, specId, assetNumber,
        function (result) {
          window.this.$log.debug(result);
          if (result.Error.value.ErrorNum.value == 0) {
            window.NelzeSchvalovat = false
            
            notify({
              message: self.$t('pozadavky-ObjednavkaZmenyUlozeny'),
              position: {
                my: 'center top',
                at: 'center top',
              },
            }, 'success', 3000);

          }
          else {
            notify({
              message: result.Error.value.ErrorTextCZ.value,
              position: {
                my: 'center top',
                at: 'center top',
              },
            }, 'error', 3000);
            self.SetNotifyError(5000);
          }
          self.PolozkyObjednavkyGrid(self.obj);
        }, window.this.ApiError());
    },
    /*itemTemplateSelectBoxEditor(itemData) {
      return {
        template: (itemElement) => {
          const itemDiv = document.createElement('div');
          itemDiv.textContent = itemData.content;
          itemDiv.title = itemData.name;

          itemDiv.addEventListener('mouseover', () => this.onItemHover(itemData));
          itemDiv.addEventListener('mouseout', () => this.onItemHoverOut(itemData));

          itemElement.append(itemDiv);
        }
      };
    },*/
    handleMouseOverSelectBoxEditor(itemData) {
      console.log("Hovered over:", itemData);
    },
    handleMouseOutSelectBoxEditor(itemData) {
      console.log("Hover out:", itemData);
    },
  },
}
  ;     
</script>

<style scoped>

:root{
  --colorByThemeQuarterOpacity: none;
}

/* Styly CLASS */
:deep(.dx-scrollable-content){
  padding-bottom:4px !important;
}
:deep(.dx-datagrid-form-buttons-container .dx-widget.dx-button:first-child){
  background-color: #5cb85c;
  color: #fff;
}
/*
:deep(.dx-datagrid-form-buttons-container .dx-widget.dx-button:last-child){
  background-color: pink;
}
*/
:deep(.dx-datagrid-form-buttons-container .dx-widget.dx-button:first-child.dx-state-hover) {
     background-color: #46a046;
}
.actionsbuttons {
  clear: both;
  flex-direction: row;
  display: inline-block;
}
.ResponsiveBoxClass{
      padding: 10px;
}
.dx-toolbar-text-auto-hide .dx-button .dx-button-content {
  background-color: red !important;
}
.DxSelectBoxResponsive{margin: 5px;}


/* Styly ID */


#upravit {
  margin-bottom: 10px;
}
#button {
  margin-right: 40px;
}
label {
  margin-right: 20px;
}
#text1 {
  text-align: top;
  height: 70px;
  width: 300px;
}
input {
  text-align: top;
  margin-right: 40px;
}
#obaleniGrid{ 
    padding: 10px;
}
#PrvniResponsiveBox, #DruhyResponsiveBox{
  border-style: ridge;
  border-color: rgba(128, 128, 128, 0.1);
  border-radius: 10px;
  
  background-color: rgba(128, 128, 128, 0.075);
  flex: none !important;
}
#PrvniResponsiveBox{margin-bottom: 10px;}
#h2g,#h2h{ 
  text-align: center;
  margin: 10px 0px 0px;
}
#activa, #vytvorit{
  text-align: center; 
  margin: 2px;  
}
#activa{background-color: rgba(183, 0, 255, 0.5);}
#activa:hover{background-color: rgba(183, 0, 255, 0.8);}
#obaleniDataGridu{ 
  margin-left: 10px; 
  margin-right: 10px;
}
#obaleniDataGridu{
  margin-bottom: 50px;
}



/* Pravidla, RULES*/


@media only screen and (max-width: 768px) {
    
      #obaleniGrid{margin-bottom: 125px;}
}/*xs */
@media only screen and (min-width: 778px) and (max-width: 992px) {
   
    #obaleniGrid{margin-bottom: 75px;}
}
/*sm */
@media only screen and (min-width: 992px) and (max-width: 1200px) {
    .ObjednavkaObjednatOd{margin-left: 85px;}
}/*md */
@media only screen and (min-width: 1200px) {
    
    .ObjednavkaObjednatOd{margin-left: 75px;}
} /*lg */
#PridatZaznam{background-color:#5cb85c; scale: 85%;}
#PridatZaznam:hover{background-color: #46a046;
scale: 85%;}
#ObnovitRozlozeni{scale: 85%; color: inherit; border-color: gray;}
.buttonDefault:hover,.buttonUlozitProUpravy:hover,.buttonKeSchvaleni:hover,.buttonSmazat:hover,.buttonKEditaci:hover{
    background-color: var(--colorByThemeLighter);
    border: 0px;
    
}
.buttonKeSchvaleni{
  background: #5cb83c;
  border-color: none;
  border: 0px;
}
.buttonUlozitProUpravy{
    background: #3cb8b8;
    border-color: none; 
    border: 0px;
}
.buttonSmazat{
    background: #d9534f;
  border-color: none;
  border: 0px;
}
.buttonKEditaci{
    background: #ffcc33;
    border-color: none;
    border: 0px; 
}
.buttonDefault{
    background: none;
    border-color: none;
    border: 0px;
}

</style>